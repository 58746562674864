import React, { useEffect, useState } from 'react';
import { AutocompleteArrayInput, BooleanInput, Edit, SimpleForm, TextInput, useGetList } from 'react-admin';
import { Grid } from '@mui/material';

const AppGroupEdit = (props: any) => {
  const [apps, setApps] = useState([]);

  const appList: any = useGetList(
    'appsList',
  );

  useEffect(() => {
    if (appList.data) {
      setApps(appList.data?.map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appList.data]);

  return (
    <Edit
      title='Edit App Group'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <Grid container sx={{ pl: '30px', pr: '30px' }}>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
            <TextInput source='name' sx={{ minWidth: '300px' }} />

            <BooleanInput label='Enable' source='isEnabled' sx={{ mt: '15px' }} />
            {/*</Grid>*/}
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
            <AutocompleteArrayInput
              source='apps'
              choices={apps}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default AppGroupEdit;
