import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  EditButton,
  Filter,
  SearchInput, DatagridConfigurable, FunctionField,
} from 'react-admin';
import { red } from '@material-ui/core/colors';

const CustomFilter = (props: any) => (
  <Filter {...props} >
    <SearchInput placeholder='Group name' source='name' resettable alwaysOn />
  </Filter>
);

const Blacklist = (props: any) => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
    >
      <DatagridConfigurable
        rowClick='edit'
      >
        <TextField source='demoId' label='#' />
        <TextField source='groupName' />
        <TextField source='botKey' />
        <FunctionField
          label='Blocked Users'
          render={(record: any) => {
            console.log(record);
            return <div>
              <span
                style={{ color: red[800], fontWeight: 600 }}
              >
                { record.blacklistedUsers.length }
              </span>
              <span>
                /
              </span>
              <span>
                { record.users.length }
              </span>
            </div>;
          }}
        />
        <DeleteButton mutationMode='pessimistic' />
      </DatagridConfigurable>
    </List>
  );
};

export default Blacklist;
