import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Filter,
  SearchInput,
  TextInput,
  useGetList
} from 'react-admin';
import React, { useEffect, useState } from 'react';

const CustomPushFilter = (props: any) => {
  const [geo, setGeo] = useState([]);
  const [apps, setApps] = useState([]);

  const geosList: any = useGetList(
    'geosArray',
  );

  useEffect(() => {
    if (geosList.data) {
      setGeo(geosList.data?.map((record: any) => ({ id: record.code, name: record.countryEn })));
    }
  }, [geosList.data]);

  const appList: any = useGetList(
    'appsList',
  );

  useEffect(() => {
    if (appList.data) {
      setApps(appList.data?.map((record: any) => ({ ...record, label: record.name })));
    }
  }, [appList.data]);

  return (
    <Filter {...props} >
      <TextInput sx={{ minWidth: '250px' }} placeholder='Push name' source='name' resettable alwaysOn />
      <AutocompleteArrayInput
        sx={{ minWidth: '250px' }}
        source='app_id'
        label='Applications'
        choices={[
          { id: 'allApps', name: 'All Apps'},
          { id: 'allAviator', name: 'All Aviator'},
          { id: 'allBetting', name: 'All Betting'},
          { id: 'allGambling', name: 'All Gambling'},
          ...apps
        ]}
        alwaysOn
      />
      <AutocompleteInput
        sx={{ minWidth: '250px' }}
        source='status'
        choices={[
          { id: 'Активно', name: 'Активно' },
          { id: 'Остановлено', name: 'Остановлено' },
          { id: 'Ошибка', name: 'Ошибка' },
        ]}
        label='Status'
        alwaysOn
      />
      <AutocompleteArrayInput
        sx={{ minWidth: '250px' }}
        source='country'
        label='GEO'
        choices={[
          { id: 'all', name: 'All GEO' },
          ...geo
        ]}
        alwaysOn
      />
    </Filter>
  )
};

export default CustomPushFilter