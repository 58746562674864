import React, { useEffect, useState } from 'react';
import {
  Form,
  TextInput,
  SaveButton,
  Title,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  useGetList,
  useNotify,
} from 'react-admin';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Box from '@mui/material/Box';
import AutocompleteWithSelectAllMixin from '../apps/AutoComplete';
import { pushLanguages } from '../../data/pushLanguages';

export const transformArray = (arr: any) => {
  return arr.map((obj: any) => {
    if (Object.keys(obj).length === 2) {
      const values = Object.values(obj);
      return { [`${values[0]}`]: values[1] };
    }
  }).filter(Boolean);
}

export const MomentarySending = () => {
  const [filters, setFilters] = useState({});
  const [firstFiltersInput, setFirstFiltersInput] = useState([]);
  const [apps, setApps] = useState([]);
  const [geo, setGeo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [geos, setGeos] = useState([]);
  const [langs, setLangs] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null);

  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetch('https://mirapanelapps.com/backend/push-service/getFilter', {
    // fetch(`${process.env.REACT_APP_API_URL}/push/getFilter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
      })
    })
      .then(response => response.json())
      .then(data => {
        setFilters(data);
        const filtersValue = Object.keys(data);
        // @ts-ignore
        setFirstFiltersInput(filtersValue.map((record: any) => ({ id: record, name: record })));
        handleClose();
      })
      .catch(error => {
        handleClose();
        console.error('Error during query execution:', error);
      });
  }, []);

  const appList: any = useGetList(
    'appsList',
  );

  const geosList: any = useGetList(
    'geosArray',
  );

  useEffect(() => {
    if (geosList.data) {
      setGeo(geosList.data?.map((record: any) => ({ id: record.code, name: record.countryEn })));
    }
  }, [geosList.data]);

  useEffect(() => {
    if (appList.data) {
      setApps(appList.data?.map((record: any) => ({ ...record, label: record.name })));
    }
  }, [appList.data]);

  const handleSubmit = (values: any) => {
    const data = {
      name: values.name,
      title: values.title,
      app_id: values.app,
      offer: values.offer,
      event: values.event,
      geo: geos.map((geo: any) => (geo.id)) || ['all'],
      lang: langs.map((lang: any) => (lang.id)) || ['all'],
      iconUrl: values.iconUrl,
      logoUrl: values.logoUrl,
      link: values.link,
      message: values.message,
      or: values.or,
      additionalPush: values.additionalPush,
      params: transformArray(values.params),
    }

    fetch('https://mirapanelapps.com/backend/push-service/send/moment', {
    // fetch(`${process.env.REACT_APP_API_URL}/push/send/moment`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        notify(
          <Alert severity='success' variant='filled'>
            <AlertTitle>Success</AlertTitle>
            Push sent!
          </Alert>,
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          });
      })
      .catch(error => {
      });
  };

  return (
    <>
        <Card>
          <Title title='Moment Sending' />
          <CardContent>
            <Form onSubmit={handleSubmit}>
              <Grid container sx={{ pl: '30px', pr: '30px' }}>
                <Grid item xs={12}>
                  <TextInput source='name' label='Task name' fullWidth />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <AutocompleteArrayInput
                    source='app'
                    label='Applications'
                    choices={[
                      { id: 'allApps', name: 'All Apps'},
                      { id: 'allAviator', name: 'All Aviator'},
                      { id: 'allBetting', name: 'All Betting'},
                      { id: 'allGambling', name: 'All Gambling'},
                      ...apps
                    ]}
                    fullWidth
                  />
                  <AutocompleteWithSelectAllMixin
                    multiple
                    fullWidth
                    options={[
                      { id: 'all', name: 'All GEO' },
                      ...geo
                    ] as any}
                    setValue={setGeos}
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    value={geos}
                    renderInput={(params: any) => <TextField {...params} label={"GEO"} />}
                  />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <Grid item xs={6} sx={{ display: 'flex' }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ mt: '41px', color: '#666666', mr: '15px' }}
                    >
                      Parameters:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ mt: '41px', mr: '10px' }}
                    >
                      AND
                    </Typography>
                    <BooleanInput
                      label='OR'
                      source='or'
                      sx={{ mt: '36px', mr: '20px' }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ mt: '22px' }}>
                    <AutocompleteWithSelectAllMixin
                      multiple
                      fullWidth
                      options={[
                        { id: 'all', name: 'All languages' },
                        ...pushLanguages
                      ] as any}
                      setValue={setLangs}
                      disableCloseOnSelect
                      filterSelectedOptions
                      freeSolo={false}
                      value={langs}
                      renderInput={(params: any) => <TextField {...params} label={"Languages"} />}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <ArrayInput onClick={() => {
                    if (firstFiltersInput.length === 0) {
                      handleOpen();
                    }
                  }} source="params" label='Add parameters'>
                    <SimpleFormIterator disableReordering inline>
                      <AutocompleteInput
                        sx={{ width: '170px', mr: '10px' }}
                        source='firstInput'
                        label='Select parameter'
                        choices={firstFiltersInput}
                        onChange={(event, newValue: any) => setSelectedParameter(newValue)}

                      />
                      <FormDataConsumer>
                        {({ formData, scopedFormData, getSource, ...rest }: any) => {
                          let variants: any[];
                          // @ts-ignore
                          if (scopedFormData.firstInput && filters[scopedFormData.firstInput]) {
                            // @ts-ignore
                            variants = filters[scopedFormData.firstInput].map((sub: string, index: number) => ({ id: sub, name: sub }));
                          } else {
                            variants = [];
                          }
                          return (
                            <AutocompleteInput
                              sx={{ minWidth: '480px' }}
                              label='Select option'
                              source={getSource('secondInput')}
                              choices={variants}
                              {...rest}
                            />
                          )
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <Grid item xs={6} sx={{ mt: '0px' }}>
                    <AutocompleteInput
                      source='event'
                      label='An event that occurred'
                      choices={[
                        { id: 'none', name: 'Without event' },
                        { id: 'install', name: 'Install' },
                        { id: 'registration', name: 'Registration' },
                        { id: 'deposit', name: 'Deposit' },
                      ]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ mt: '0px' }}>
                    <BooleanInput
                      label='Additional push'
                      source='additionalPush'
                      sx={{ mt: '13px' }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <TextInput source='title' label='Caption' fullWidth />
                  <TextInput source='logoUrl' label='Image URL' fullWidth />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <TextInput source='iconUrl' label='Icon URL' fullWidth />
                  <TextInput source='link' label='Link when opening' fullWidth />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: '30px' }}>
                  <TextInput
                    source='message'
                    label='Message'
                    rows={3}
                    fullWidth
                    multiline
                    resettable
                  />
                </Grid>

                <Grid item xs={12}>
                  <SaveButton label='Send' />
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>

      <Box sx={{ zIndex: 1 }}>
        <Backdrop
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </Box>
    </>
  );
};

