import { useRecordContext } from 'ra-core';
import React, { memo, useEffect, useState } from 'react';
import { CountdownTimer } from './CountdownTimer';

export const TimerField = memo(() => {
  const record = useRecordContext();
  const [endDate, setEndDate] = useState(new Date(record.last_change_post));

  useEffect(() => {
    setEndDate(new Date(record.last_change_post));
  }, [record.last_change_post]);

  if (record.isBaned || record.fbMark) {
    return null;
  }

  return <CountdownTimer endDate={endDate} />;
});
