import * as React from 'react';
import {
  Card, CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link, useRecordContext } from 'react-admin';
import { TableCellRight } from './TableCellRight';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

const OsCamps = () => {
  const [osCamps, setOsCamps] = useState([]);
  const [open, setOpen] = useState(true);
  const record = useRecordContext();

  const { id } = record;

  useEffect(() => {
    const getOsCampsData = async (campId: any) => {
      const response = await axios.post(
        `https://mirapanelapps.com/backend/push-service/oscamps`,
        // `${process.env.REACT_APP_API_URL}/push-service/oscamps`,
        { campaignId: campId }
      );

      if (response.data.success && response.data.payload !== null) {
        setOsCamps(response.data.payload);
        setOpen(false);
      } else {
        setOsCamps([]);
        setOpen(false);
      }
    }
    getOsCampsData(id);
  }, []);

  return (
    <>
      {open && (
        <Grid item xs={12} sx={{ zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress color='inherit' />
        </Grid>
      )}
      {!open && osCamps.length > 0 && (
        <Grid container >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={10}>
              <Card sx={{ margin: 'auto' }}>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          ID
                        </TableCell>
                        <TableCellRight>
                          App
                        </TableCellRight>
                        <TableCellRight>
                          Onesignal ID
                        </TableCellRight>
                        <TableCellRight>
                          Created
                        </TableCellRight>
                        <TableCellRight>
                          Auditory
                        </TableCellRight>
                        <TableCellRight>
                          Sent
                        </TableCellRight>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {osCamps.map((item: any) => {
                        const { os_app_id, os_id } = item;

                        const osUrl = `https://dashboard.onesignal.com/apps/${os_app_id}/notifications/${os_id}`;

                        return (
                          <TableRow key={item.id}>
                            <TableCell>
                              {item.id}
                            </TableCell>
                            <TableCellRight>
                              {item.app}
                            </TableCellRight>
                            <TableCellRight>
                              <Link target='_blank' to={osUrl}>
                                {item.os_id}
                              </Link>
                            </TableCellRight>
                            <TableCellRight>
                              {item.created}
                            </TableCellRight>
                            <TableCellRight>
                              {item.auditory}
                            </TableCellRight>
                            <TableCellRight>
                              {item.sent}
                            </TableCellRight>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OsCamps;
