import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRecordContext, useTheme } from 'react-admin';
import { Grid, IconButton, MenuItem, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { pushLanguages } from '../../data/pushLanguages';
import { makeStyles } from '@material-ui/core';

const TabsContext = createContext({
  tabs: [],
  handleTabsChange(tabs: any) {}
});

const Form = React.memo(({ id, onRemove, tabIndex, onFormChange, formIndex, form }: any) => {
  const [lang, setLang] = useState(form.lang);
  const [header, setHeader] = useState(form.header);
  const [message, setMessage] = useState(form.message);
  const [frmId, ] = useState(form.id);

  useEffect(() => {
    onFormChange(tabIndex, formIndex, { lang, message, header, id: frmId });
  }, [lang, message, header, onFormChange, tabIndex, formIndex, frmId]);

  const handleRemove = useCallback(() => {
    onRemove(tabIndex, id);
  }, [id, onRemove, tabIndex]);

  const handleLangChange = useCallback((event: any)  => {
    setLang(event.target.value);
  }, []);

  const handleHeaderChange = useCallback((event: any) => {
    setHeader(event.target.value);
  }, []);

  const handleMessageChange = useCallback((event: any) => {
    setMessage(event.target.value);
  }, []);

  return (
    <Paper style={{ backgroundColor: 'rgba(185,229,239,0.3)', padding: '20px', marginBottom: '10px', marginTop: '20px', position: 'relative', width: '70%' }}>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} md={2}>
          <Typography style={{ fontWeight: '500', color: '#2196f3' }}>#{id}</Typography>
          <FormControl sx={{ m: 2, ml: 4, minWidth: 100, width: 120 }}>
            <InputLabel id="demo-simple-select-filled-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={lang}
              onChange={handleLangChange}
              fullWidth
              label="Language"
            >
              {pushLanguages.map((lang: any) => {
                return (<MenuItem key={lang.id} value={lang.id}>{lang.name}</MenuItem>)
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={10} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Header"
              value={header}
              onChange={handleHeaderChange}
              variant="filled"
              fullWidth
              error={header && header.length > 50}
              helperText={header && header.length > 50 ? `Ensure header length is not more than 50 characters! ${header.length}/50` : ''}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Message"
              value={message}
              onChange={handleMessageChange}
              variant="filled"
              fullWidth
              error={message && message.length > 150}
              helperText={message && message.length > 150 ? `Ensure message length is not more than 150 characters! ${message.length}/150` : ''}
              inputProps={{ maxLength: 151 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <IconButton
        color="secondary"
        onClick={handleRemove}
        style={{ position: 'absolute', top: '60px', right: '-2px' }}
      >
        <ClearIcon />
      </IconButton>

    </Paper>
  );
});

const useTabFormState = (initialState: any) => {
  const [title, setTitle] = useState(initialState.title);
  const [enabled, setEnabled] = useState(initialState.enabled);
  const [iconUrl, setIconUrl] = useState(initialState.icon_url);
  const [logoUrl, setLogoUrl] = useState(initialState.logo_url);

  return {
    title,
    enabled,
    iconUrl,
    logoUrl,
    setTitle,
    setEnabled,
    setIconUrl,
    setLogoUrl,
  };
};

const TabForm = React.memo(({ tab, onAddForm, onRemoveForm, onTitleChange, onEnabledChange, onIconUrlChange, onLogoUrlChange, activeTab, tabIndex, onFormChange }: any) => {
  const [forms, setForms] = useState(tab.content);

  const localState = useTabFormState(tab);
  console.log('Tab Form');
  const handleRemoveForm = (tabIndex: any, formId: any) => {
    setForms((prevForms: any) => {
      return prevForms.filter((obj: any) => obj.id !== formId);
    });

    return onRemoveForm(tabIndex, formId);
  }

  useEffect(() => {
    onTitleChange(tabIndex, localState.title);
  }, [tabIndex, localState.title, onTitleChange]);

  useEffect(() => {
    onEnabledChange(tabIndex, localState.enabled);
  }, [tabIndex, localState.enabled, onEnabledChange]);

  useEffect(() => {
    onIconUrlChange(tabIndex, localState.iconUrl);
  }, [tabIndex, localState.iconUrl, onIconUrlChange]);

  useEffect(() => {
    onLogoUrlChange(tabIndex, localState.logoUrl);
  }, [tabIndex, localState.logoUrl, onLogoUrlChange]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label='Push title'
            variant="filled"
            fullWidth
            value={localState.title}
            onChange={(event) => localState.setTitle(event.target.value)}
            style={{ marginTop: '20px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={localState.enabled}
                onChange={() => localState.setEnabled(!localState.enabled)}
                color="primary"
                inputProps={{ 'aria-label': 'Switch' }}
              />
            }
            label="Enabled"
            style={{ marginBottom: '8px', marginTop: '20px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Icon Url'
            variant="filled"
            fullWidth
            value={localState.iconUrl}
            onChange={(event) => localState.setIconUrl(event.target.value)}
            style={{ marginBottom: '8px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Logo URL'
            variant="filled"
            fullWidth
            value={localState.logoUrl}
            onChange={(event) => localState.setLogoUrl(event.target.value)}
            style={{ marginBottom: '8px' }}
          />
        </Grid>
        {forms.map((form: any, index: any) => (
          <Form
            key={form.id}
            id={form.id}
            form={form}
            tabIndex={tabIndex}
            formIndex={index}
            onRemove={handleRemoveForm}
            setForms={setForms}
            onFormChange={onFormChange}
          />
        ))}
        <Grid item xs={12}>
          <IconButton
            color="primary"
            onClick={() => {
              setForms((prevForms: any) => {
                return [...prevForms, { id: prevForms.length + 1 }];
              })
              return onAddForm();
            }}
            style={{ marginTop: '' }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
});


// const FormTabs = React.memo(({ tabs: iniTialTabs, onChange }: any) => {
const FormTabs = React.memo((props: any) => {
  const { currTabs, handleTabsChange }: any = useContext(TabsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [tabs, setTabs] = useState(currTabs);
  const [activeTab, setActiveTab] = useState(0);
  const record = useRecordContext(props);
  const [theme, ] = useTheme();

  useEffect(() => {
    if (currTabs && currTabs !== record?.payload) {
      setTabs(currTabs);
      setIsLoading(false);
    } else if (record && record.payload && currTabs !== record.payload) {
      setTabs(record.payload);
      setIsLoading(false);
    }
  }, [record]);

  useEffect(() => {
    handleTabsChange(tabs);
  }, [handleTabsChange, tabs]);

  const handleTabChange = useCallback((_, newTab) => {
    setActiveTab(newTab);
  }, []);

  const handleAddTab = useCallback(() => {
    setTabs((prevTabs: any) => {
      console.log(prevTabs);
      return [
        ...prevTabs,
        {
          id: prevTabs.length + 1,
          // title: `Push ${prevTabs.length + 1}`,
          title: `unnamed`,
          icon_url: '',
          logo_url: '',
          created: '',
          enabled: true,
          archived: false,
          content: [{ id: 1, lang: 'en', message: '', header: '' }],
        },
      ] as any
    } );
  }, []);

  const handleRemoveTab = useCallback((id) => {
    setTabs((prevTabs: any) => {
      const newTabs = prevTabs.filter((tab: any) => tab.id !== id);
      const newActiveTab = Math.min(activeTab, newTabs.length - 1);
      setActiveTab(newActiveTab >= 0 ? newActiveTab : 0);
      return newTabs;
    });
    // setTabs((prevTabs: any) => {
    //   const newTabs = prevTabs.map((tab: any) => {
    //     if (tab.id === id) {
    //       return { ...tab, archived: true };
    //     }
    //     return tab;
    //   });
    //
    //   const newActiveTab = Math.min(activeTab, newTabs.length - 1);
    //   setActiveTab(newActiveTab >= 0 ? newActiveTab : 0);
    //
    //   return newTabs;
    // });
  }, [activeTab]);

  const handleAddForm = useCallback(() => {
    setTabs((prevTabs: any) => {
      const newForm: any = { id: prevTabs[activeTab].content.length + 1, lang: '', message: '', header: '' };
      const updatedTabs: any = [...prevTabs];
      updatedTabs[activeTab].content.push(newForm);
      return updatedTabs;
    });
  }, [activeTab]);

  const handleRemoveForm = useCallback((tabIndex, formId) => {
    setTabs((prevTabs: any) => {
      const updatedTabs: any = [...prevTabs];
      updatedTabs[tabIndex].content = updatedTabs[tabIndex].content.filter(
        (form: any) => form.id !== formId
      );
      return updatedTabs;
    });
  }, []);

  const handleTitleChange = useCallback((index, newName) => {
    setTabs((prevTabs: any) =>
      prevTabs.map((tab: any, i: any) => (i === index ? { ...tab, title: newName } : tab))
    );
  }, []);

  const handleIconUrlChange = useCallback((index, newIconUrl) => {
    setTabs((prevTabs: any) =>
      prevTabs.map((tab: any, i: any) => (i === index ? { ...tab, icon_url: newIconUrl } : tab))
    );
  }, []);

  const handleLogoUrlChange = useCallback((index, newLogoUrl) => {
    setTabs((prevTabs: any) =>
      prevTabs.map((tab: any, i: any) => (i === index ? { ...tab, logo_url: newLogoUrl } : tab))
    );
  }, []);

  const handleEnabledChange = useCallback((index, newEnabledValue) => {
    setTabs((prevTabs: any) =>
      prevTabs.map((tab: any, i: any) => (i === index ? { ...tab, enabled: newEnabledValue } : tab))
    );
  }, []);

  const handleFormChange = useCallback((tabIndex, formIndex, updatedForm) => {
    setTabs((prevTabs: any) => {
      // const updatedTabs: any = [...prevTabs];
      return prevTabs.map((tab: any, index: number) => {
        if (index === tabIndex) {
          return {
            ...tab,
            content: tab.content
              .map((form: any, index: number) => (index === formIndex ? { ...updatedForm } : form)),
          }
        } else {
          return tab;
        }
      });
      // updatedTabs[tabIndex].content[formIndex] = updatedForm;
      // return updatedTabs;
    });
  }, []);

  return (
    <div style={{ width: '84vw', maxWidth: '100%', margin: '0' }}>
      {!isLoading &&
        (<>
          {/*<IconButton color='primary' onClick={handleAddTab} style={{ marginLeft: '8px' }}>*/}
          {/*  <AddIcon />*/}
          {/*</IconButton>*/}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
            style={{ overflowX: 'auto', marginTop: '20px' }}
          >
            {!isLoading && tabs && tabs.map((tab: any, index: any) => {

             // if (!tab.archived) {
               return (
                 <Tab
                   key={index}
                   label={
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                       <span style={{ marginRight: '8px' }}>{tab.title}</span>
                       {tabs.length > 1 && index === activeTab && (
                         <IconButton color='secondary' onClick={() => handleRemoveTab(tab.id)}>
                           <ClearIcon
                             color={theme === 'dark' ? 'primary' : 'disabled'}
                             fontSize='small'
                           />
                         </IconButton>
                       )}
                     </div>
                   }
                 />
               )
             // }
             // return null;
            })}
            <div>
              <IconButton
                color='primary'
                onClick={handleAddTab}
                style={{ marginLeft: '8px', marginTop: '8px', width: '100%' }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Tabs>
          {/*{!isLoading && tabs.map((tab: any, index: any) => (*/}
          {!isLoading && tabs && (
            <TabForm
              key={activeTab}
              tab={tabs[activeTab]}
              tabIndex={activeTab}
              activeTab={activeTab}
              onTitleChange={handleTitleChange}
              onEnabledChange={handleEnabledChange}
              onIconUrlChange={handleIconUrlChange}
              onLogoUrlChange={handleLogoUrlChange}
              onAddForm={handleAddForm}
              onRemoveForm={handleRemoveForm}
              onFormChange={handleFormChange}
            />
          )}
          {/*))}*/}
        </>)}
    </div>
  );
});



export { TabsContext, FormTabs };
