import React, { Children, ReactElement } from 'react';
import { Box, Card, CardContent, IconButton, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
  RecordContextProvider,
  useCreatePath,
  useListContext,
  useResourceContext,
  useTranslate,
} from 'react-admin';

import EditIcon from '@mui/icons-material/Edit';

import AvatarField from './AvatarField';

const DesktopGrid = ({ children }: { children: ReactElement[] }) => {
  const { data } = useListContext();
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const theme = useTheme();

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        margin: '1em',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {data.map(record => {
        console.log(record);
        return (
          <RecordContextProvider value={record} key={record.id}>
            <Card
              sx={{
                width: 250,
                backgroundColor: 'darkslategrey',
                display: 'flex',
                border: '3px solid black',
                borderRadius: 2,
                flexDirection: 'row',
                margin: '0.5rem 1rem',
              }}
            >
              <CardContent
                sx={{
                  // ...theme.typography.body1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                    // position: 'relative',
                  }}
                >
                  <AvatarField record={record} size="100" />
                  <h3>{`${record.name}`}</h3>

                  {/*<IconButton*/}
                  {/*  color="secondary"*/}
                  {/*  aria-label="Edit"*/}
                  {/*  component={RouterLink}*/}
                  {/*  sx={{*/}
                  {/*    color: 'green',*/}
                  {/*    position: 'absolute',*/}
                  {/*    top: 0,*/}
                  {/*    right: 0,*/}
                  {/*  }}*/}
                  {/*  to={createPath({*/}
                  {/*    resource,*/}
                  {/*    id: record.id,*/}
                  {/*    type: 'edit',*/}
                  {/*  })}*/}
                  {/*>*/}
                  {/*  <EditIcon />*/}
                  {/*</IconButton>*/}
                </Box>
                {/*{Children.map(children, field => {*/}
                {/*  const fieldName = field.props.source;*/}
                {/*  const fieldLabel = field.props.label;*/}
                {/*  console.log(field.props);*/}
                {/*  if (*/}
                {/*    fieldName === 'logo' ||*/}
                {/*    fieldName === 'demoId' ||*/}
                {/*    fieldName === 'name' ||*/}
                {/*    fieldName === 'os' || fieldLabel === 'OS' ||*/}
                {/*    fieldName === 'name' || fieldLabel === 'Name' ||*/}
                {/*    fieldName === 'package' || fieldLabel === 'Package' ||*/}
                {/*    fieldName === 'source' || fieldLabel === 'Source' ||*/}
                {/*    fieldName === 'bot_available' ||*/}
                {/*    // fieldName === 'isBaned' ||*/}
                {/*    fieldName === 'vertical' ||*/}
                {/*    fieldName === 'fbMark'*/}
                {/*  ) {*/}
                {/*    return null;*/}
                {/*  }*/}

                {/*  return (*/}
                {/*    <div*/}
                {/*      key={fieldName}*/}
                {/*      style={{*/}
                {/*        display: 'flex',*/}
                {/*        flexWrap: 'wrap',*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <div*/}
                {/*        style={{*/}
                {/*          marginRight: '1em',*/}
                {/*          marginBottom: '0.5em',*/}
                {/*          marginTop: '1em',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        { fieldLabel }*/}
                {/*      </div>*/}
                {/*      <div*/}
                {/*        style={{*/}
                {/*          whiteSpace: 'nowrap',*/}
                {/*          marginBottom: '0.5em',*/}
                {/*          marginTop: '1em',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        {field}*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  );*/}
                {/*})}*/}
              </CardContent>
            </Card>
          </RecordContextProvider>
        )
      })}
    </Box>
  );
};

export default DesktopGrid;
