import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  EditButton,
} from 'react-admin';

const AccountList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit'>
        <TextField source='demoId' label='#' />
        <TextField source='login' />
        <TextField source='proxy' />
        <EditButton />
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  )
}

export default AccountList
