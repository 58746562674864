import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Card,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@material-ui/icons/Add';
import { useGetList } from 'react-admin';
import { Box } from '@material-ui/core';
import { useClipboard } from 'use-clipboard-copy';

function countInstallsByTier(data: any, installCosts: any) {
  console.log(data);
  const result = {
    tier1: 0,
    tier2: 0,
    tier3: 0
  };

  let resultString = '';

  for (const obj of data) {
    if (obj.tier === 1) {
      result.tier1 += obj.installs;
    } else if (obj.tier === 2) {
      result.tier2 += obj.installs;
    } else if (obj.tier === 3) {
      result.tier3 += obj.installs;
    }

    resultString += `${obj.countryEn} - ${obj.installs} tier ${obj.tier}\n`;
  }

  const totalCost =
    result.tier1 * installCosts.tier1 +
    result.tier2 * installCosts.tier2 +
    result.tier3 * installCosts.tier3;

  resultString += `\n${result.tier1 ? `${result.tier1}*${installCosts.tier1}` : ''  }${result.tier2 ? `${result.tier1 ? ' + ' : ''}${result.tier2}*${installCosts.tier2}` : ''}${result.tier3 ? `${result.tier2 || result.tier1 ? ' + ' : ''}${result.tier3}*${installCosts.tier3}` : ''} = ${totalCost.toFixed(2)}\n`;
  resultString += `\nК оплате: ${Math.floor(totalCost)} $`;

  return { result, resultString };
}


const InstallCalculator = () => {
  const [countryInstalls, setCountryInstalls ] = useState([{ country: '', countryEn: '', installs: null, tier: 0 }]);
  const [geoOptions, setGeoOptions] = useState([]);
  const [calcTierValues, setCalcTierValues] = useState({ tier1: 0, tier2: 0, tier3: 0 });
  const [tier1Cost, setTier1Cost] = useState('');
  const [tier2Cost, setTier2Cost] = useState('');
  const [tier3Cost, setTier3Cost] = useState('');
  const [calcStr, setCalcStr] = useState('');

  const geosList: any = useGetList(
    'geosArray',
  );

  const clipboard = useClipboard();

  useEffect(() => {
    if (geosList.data) {
      console.log(geoOptions);
      setGeoOptions(geosList.data);
    }
  }, [geosList.data]);

  const handleSelect1Change = (index: number, value: any) => {
    const updatedForms = countryInstalls.map((country, i) =>
      i === index ? { ...country, tier: value.tier, country: value.country, countryEn: value.countryEn } : country
    );
    setCountryInstalls(updatedForms);
  };

  const handleSelect2Change = (index: number, value: any) => {
    const updatedForms = countryInstalls.map((country, i) =>
      i === index ? { ...country, installs: +value as any } : country
    );
    setCountryInstalls(updatedForms);
  };

  const handleAddForm = () => {
    setCountryInstalls((prev: any) => ([...prev, { country: '', countryEn: '', installs: null, tier: 0 }]));
  };

  const handleRemoveForm = (index: number) => {
    setCountryInstalls((prev) => {
      return (prev.filter((_, i) => {
        return i !== index;
      }));
    });
  };

  const handleTier1Change = (event: any) => {
    setTier1Cost(event.target.value);
  };

  const handleTier2Change = (event: any) => {
    setTier2Cost(event.target.value);
  };

  const handleTier3Change = (event: any) => {
    setTier3Cost(event.target.value);
  };

  return (
      <Card sx={{ paddingTop: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '10px', marginLeft: '16px' }}>
            <Grid item xs={1}>
              <TextField
                label="Tier1"
                type="number"
                value={tier1Cost}
                onChange={handleTier1Change}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Tier2"
                type="number"
                value={tier2Cost}
                onChange={handleTier2Change}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Tier3"
                type="number"
                value={tier3Cost}
                onChange={handleTier3Change}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '30px', display: 'flex', flexDirection: 'row'}}>
            <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', columnGap: '-30px'}}>
              {countryInstalls.map((country: any, index) => (
                <div key={index} style={{ margin: '0 16px 0', width: '100%' }}>
                  <Grid item sx={{ display: 'flex', columnGap: '15px' }}>
                    <Grid
                      item
                      // xs={2}
                      sx={{ display: 'flex' }}
                    >
                      <Autocomplete
                        disableClearable
                        sx={{ width: '250px' }}
                        options={[...geoOptions, {code: '', country: 'Other', countryEn: 'Other', tier: 3}]}
                        onChange={(_, value) => handleSelect1Change(index, value)}
                        autoHighlight
                        value={country}
                        getOptionLabel={(option: any) => option.countryEn}
                        renderOption={(props, option) => (
                          // @ts-ignore
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                              style={{ marginRight: '10px' }}
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.countryEn} { option.code.length > 0 ? `(${option.code})` : '' } tier {option.tier}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      // xs={2}
                      sx={{ display: 'flex' }}
                    >
                      <TextField
                        sx={{ width: '200px' }}
                        helperText=" "
                        label="Installs"
                        // type='number'
                        value={country.installs || ''}
                        onChange={(event) => handleSelect2Change(index, event.target.value)}
                      />
                      <Grid item sx={{ display: 'flex', height: '60px', paddingTop: '5px' }}>
                        <IconButton
                          color="secondary"
                          onClick={() => handleRemoveForm(index)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <IconButton
                style={{ marginBottom: '60px', width: '60px' }}
                color="primary"
                onClick={handleAddForm}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={3}>
                <Typography sx={{ marginBottom: '-15px', marginTop: '0px', padding: '0px', fontSize: '20px', fontWeight: '500' }} display="block" variant="overline" gutterBottom>
                  Tier 1: {calcTierValues.tier1}
                </Typography>
                <Typography sx={{ marginBottom: '-15px', marginTop: '0px', padding: '0px', fontSize: '20px', fontWeight: '500' }} display="block" variant="overline" gutterBottom>
                  Tier 2: {calcTierValues.tier2}
                </Typography>
                <Typography sx={{ marginTop: '0px', padding: '0px', fontSize: '20px', fontWeight: '500' }} display="block" variant="overline" gutterBottom>
                  Tier 3: {calcTierValues.tier3}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    const tierValues =
                      countInstallsByTier(countryInstalls, { tier1: tier1Cost, tier2: tier2Cost, tier3: tier3Cost });

                    clipboard.copy(tierValues.resultString);
                    setCalcTierValues(tierValues.result);
                    setCalcStr(tierValues.resultString);
                  }}
                >
                  Calculate
                </Button>
              </Grid>
              <Grid item>
                <pre>{calcStr}</pre>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
  );
}

export default InstallCalculator;
