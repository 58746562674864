import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';

const RowStyle = (record: any, theme: string) => {

  let style = {};

  if (!record) {
    return style;
  }

  if (
    record.source.includes('UAC')
    && record.google_ads_rk.length >= 1
  )
    style = {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (
    record.source.includes('UAC')
    && record.google_ads_rk.length >= 15
    && record.google_ads_rk.length < 20
  )
    style = {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (
    record.source.includes('UAC')
    && record.google_ads_rk.length === 20
  )
    style = {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };

  if (record.isBaned) {
    if (theme === 'dark') {
      style = {
        ...style,
        backgroundColor: 'rgba(65,6,6,0.55)'
      };
    } else {
      style = {
        ...style,
        backgroundColor: '#FFC0CB'
      };
    }
  }

  if (record.fbMark) {
    if (theme === 'dark') {
      style = {
        ...style,
        backgroundColor: 'rgba(0,53,94,0.8)'
      };
    } else {
      style = {
        ...style,
        backgroundColor: '#FFFFE0'
      };
    }
  }

  return style;
};

export default RowStyle;
