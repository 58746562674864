import axios from 'axios';
import { API_URL } from '../../App';

export const fetchTags = async () => {
  try {
    const response = await axios.post(`${API_URL}/push-service/tags`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data: ', error);
    return [];
  }
};

export const fetchCampaignById = async (campId: string) => {
  try {
    const response = await axios.get(`${API_URL}/push-service/campaigns/${campId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data: ', error);
    return [];
  }
};

export const fetchTagValues = async (tagName: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/push-service/tagvalues`,
      {
        tagName: tagName,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data: ', error);
    return [];
  }
};

export const fetchApps = async () => {
  try {
    const response = await axios.get(`${API_URL}/push-service/osapps`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data: ', error);
    return [];
  }
};
