import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  SearchInput,
  FilterButton,
  TopToolbar,
  useGetList,
  AutocompleteInput,
  FunctionField,
  BooleanField,
  useRefresh, useNotify,
} from 'react-admin';
import Button from '@mui/material/Button';
import BlockIcon from '@mui/icons-material/Block';
import ReplayIcon from '@mui/icons-material/Replay';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Alert, AlertTitle, Tooltip } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../App';


const ListActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const UsersList = (props: any) => {
  const [groups, setGroups] = useState([]);
  const refresh = useRefresh();
  const notify = useNotify();

  const { data }: any = useGetList(
    'groupsFilter',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (data) {
      setGroups(data?.map((record: any) => ({ id: record.name, name: record.name })));
    }
  }, [data]);

  const usersFilters = [
    <SearchInput source='username' alwaysOn />,
    <AutocompleteInput source='groupName' choices={groups}
    />,
  ];

  return (
    <List {...props} filters={usersFilters} actions={<ListActions />}>
      <Datagrid>
        <TextField source='demoId' label='#' />
        <TextField source='chatId' label='Chat ID' />
        <TextField source='group' label='Group Key' />
        <TextField source='groupName' label='Group Name' />
        <TextField source='username' />
        <FunctionField
          label='Access'
          render={(record: any) => {
            const { _id, blacklisted, groupId, username } = record;
            const buttonTittle = blacklisted
                ? 'Remove from blacklist'
                : 'Add to blacklist';

            return (
              <Tooltip title={buttonTittle} placement="top">
                <Button onClick={async () => {
                  const response = await axios.patch(
                    `${API_URL}/blacklist/user`,
                    {
                      groupId,
                      blacklisted: !blacklisted,
                      userId: _id,
                      username,
                    }
                  );

                  if (response.data.success) {
                    refresh();

                    if (blacklisted) {
                      notify(
                        <Alert severity='success' variant='filled'>
                          <AlertTitle>Success</AlertTitle>
                          User unblocked!
                        </Alert>,
                        {
                          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        });
                    } else {
                      notify(
                        <Alert severity='success' variant='filled'>
                          <AlertTitle>Success</AlertTitle>
                          User added to the blacklist!
                        </Alert>,
                        {
                          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        });
                    }

                  }
                }}
                >
                  {
                    blacklisted
                      ? <PersonOffIcon color='error' />
                      : <PersonIcon />
                  }
                </Button>
              </Tooltip>
            );
          }}
        />
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  );
};

export default UsersList;
