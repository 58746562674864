import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, Grid, IconButton, Paper, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@material-ui/icons/Add';
import { useRecordContext } from 'react-admin';
import { fetchTags, fetchTagValues } from './pushHelpers';

export interface Option {
  value: string;
  label: string;
}

const TagsInput = React.memo(({ initialTags, onChange, ...props }: any) => {
  const [tags, setTags] = useState<any[]>(initialTags);
  const [tagsArr, setTagsArr] = useState<Option[]>([]);
  const [valuesArr, setValuesArr] = useState<Option[]>([]);
  const [tagsLoad, setTagsLoad] = useState(true);
  const [valuesLoad, setValuesLoad] = useState(false);
  const [isLoadingRecordTags, setIsLoadingRecordTags] = useState(true);
  const record = useRecordContext(props);

  useEffect( () => {
    if (initialTags) {
      setTags(initialTags);
      setIsLoadingRecordTags(false);
    } else {
      if (record && record.target?.tags) {
        setTags(record.target.tags);
        setIsLoadingRecordTags(false);
      }
    }
  }, [initialTags, record]);

  useEffect(() => {
    console.log(record);
    const getTags = async () => {
      setTagsLoad(true);
      const tags = await fetchTags();
      setTagsArr(tags.payload.sort());
      setTagsLoad(false)
    }
    getTags();
  }, []);

  useEffect(() => {
    onChange(tags);
  }, [tags, onChange]);

  const handleSelect1Change = (index: number, value: Option | null) => {
    setValuesLoad(true);
    const updatedForms = tags.map((tag, i) =>
      i === index ? { ...tag, tag: value, value: null } : tag
    );
    setTags(updatedForms);

    fetchTagValues(value as any)
      .then((tagsValues: any) => {
        setValuesArr(tagsValues.payload);
        setValuesLoad(false);
      });
  };

  const handleSelect2Change = (index: number, value: any) => {
    const updatedForms = tags.map((tag, i) =>
      i === index ? { ...tag, value: value } : tag
    );
    setTags(updatedForms);
  };

  const handleAddForm = () => {
    setTags([...tags, { tag: null, value: null }]);
  };

  const handleRemoveForm = (index: number) => {
    const updatedForms = tags.filter((_, i) => i !== index);
    setTags(updatedForms);
  };


  return (
    <div>
      {isLoadingRecordTags && (
        <Grid item xs={12} sx={{ zIndex: 1, display: 'flex', pl: '100px', mb: '10px' }}>
          <Grid item xs={6} sx={{ mr: 'auto', mt: '10px' }} >
            <CircularProgress color='inherit' />
          </Grid>
        </Grid>
      )}
      {!isLoadingRecordTags && tags.map((form, index) => (
        <Paper key={index} style={{ padding: '16px', margin: '16px 0', width: '1200px' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Autocomplete
                disableClearable
                options={tagsArr}
                getOptionLabel={(option) => option}
                value={form.tag}
                onChange={(_, value) => handleSelect1Change(index, value)}
                loading={tagsLoad}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tag"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {tagsLoad ? <CircularProgress color="inherit" size={20} /> : null}
                          {!tagsLoad && params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={9.5}>
              <Autocomplete
                options={valuesLoad ? ['Loading...'] : valuesArr}
                getOptionLabel={(option) => option}
                value={form.value}
                onChange={(_, value) => handleSelect2Change(index, value)}
                loading={valuesLoad}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Value"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {valuesLoad ? <CircularProgress color="inherit" size={20} /> : null}
                          {!valuesLoad && params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5}>
              <IconButton color="secondary" onClick={() => handleRemoveForm(index)}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
      <IconButton style={{ marginBottom: '30px' }} color="primary" onClick={handleAddForm}>
        <AddIcon />
      </IconButton>
    </div>
  );
});

// const Tags: any = React.memo(({ tags: initialTags, onChange }: any) => {
//   const [forms, setForms] = useState<any[]>(initialTags);
//   const [options1, setOptions1] = useState<Option[]>([]);
//   const [options2, setOptions2] = useState<Option[]>([]);
//   const [isLoading1, setIsLoading1] = useState(true);
//   const [isLoading2, setIsLoading2] = useState(true);
//
//   useEffect( () => {
//     setIsLoading1(true)
//     const getTags = async () => {
//       const tags = await fetchTags();
//       setOptions1(tags.payload.sort().map((osTag: string) => ({ label: osTag, value: osTag })));
//       setIsLoading1(false)
//     }
//     getTags();
//   }, []);
//
//   useEffect(() => {
//     onChange(forms);
//   }, [forms, onChange]);
//
//   const handleTagChange =async (index: number, value: Option | null) => {
//     setIsLoading2(true)
//
//     if (value === null) {
//       setOptions2([])
//       setIsLoading2(false)
//       return;
//     }
//
//     const updatedForms = forms.map((form, i) =>
//       i === index ? { ...form, tag: value.value, value: null } : form
//     );
//     setForms(updatedForms);
//
//     const tagsValues = await fetchTagValues(value?.label as any);
//     setOptions2(tagsValues.payload.map((pl: any) => ({ label: pl, value: pl  })))
//     setIsLoading2(false)
//   };
//
//   const handleInputTagValueChange = useCallback((index: number, value: any) => {
//     const updatedForms = forms.map((form, i) =>
//       i === index ? { ...form, inputTagValue: value } : form
//     );
//     setForms(updatedForms);
//   }, []);
//
//   const handleTagInputValue1Change = (index: number, value: Option | null) => {
//     const updatedForms = forms.map((form, i) =>
//       i === index ? { ...form, inputValue: value } : form
//     );
//     setForms(updatedForms);
//   };
//
//   const handleAddForm =() => {
//     setForms([...forms, { tag: null, value: null, inputTagValue: '' }]);
//   };
//
//   const handleRemoveForm = (index: number) => {
//     const updatedForms = forms.filter((_, i) => i !== index);
//     setForms(updatedForms);
//   };
//
//   return (
//     <div>
//       {/*{(!isLoading2 || !isLoading1) && (*/}
//       {/*  <Grid item xs={12} sx={{ zIndex: 1, display: 'flex', mb: '20px' }}>*/}
//       {/*    <Grid item xs={6} sx={{ mr: 'auto', mt: '10px' }} >*/}
//       {/*      <CircularProgress color='inherit' />*/}
//       {/*    </Grid>*/}
//       {/*  </Grid>*/}
//       {/*)}*/}
//       {forms.map((form, index) => {
//         return (<Paper key={index} style={{ padding: '16px', margin: '16px 0', width: '1200px' }}>
//           <Grid container spacing={2}>
//             <Grid item xs={2}>
//               <Autocomplete
//                 value={form.tag}
//                 onChange={(_, value) => handleTagChange(index, value)}
//                 // inputValue={form.inputTagValue}
//                 // onInputChange={(_, value: any) => {
//                 //   if (value !== 'undefined') {
//                 //     return handleInputTagValueChange(index, value);
//                 //   }
//                 // }}
//                 loading={isLoading1}
//                 options={options1}
//                 getOptionLabel={(option) => option.label}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label="Tag"
//                     InputProps={{
//                       ...params.InputProps,
//                       endAdornment: (
//                         <React.Fragment>
//                           {isLoading1 ? <CircularProgress color="inherit" size={20} /> : null}
//                           {params.InputProps.endAdornment}
//                         </React.Fragment>
//                       ),
//                     }}
//                   />
//                 )}
//               />
//             </Grid>
//             {/*<Grid item xs={9.5}>*/}
//             {/*  <Autocomplete*/}
//             {/*    // value={form.value}*/}
//             {/*    // onChange={(_, value) => handleValueChange(index, value)}*/}
//             {/*    inputValue={form.value}*/}
//             {/*    loading={isLoading2}*/}
//             {/*    options={!isLoading2 ? options2 : []}*/}
//             {/*    getOptionLabel={(option) => option.label}*/}
//             {/*    renderInput={(params) => (*/}
//             {/*      <TextField*/}
//             {/*        {...params}*/}
//             {/*        label="Value"*/}
//             {/*        InputProps={{*/}
//             {/*          ...params.InputProps,*/}
//             {/*          endAdornment: (*/}
//             {/*            <React.Fragment>*/}
//             {/*              {isLoading2 ? <CircularProgress color="inherit" size={20} /> : null}*/}
//             {/*              {params.InputProps.endAdornment}*/}
//             {/*            </React.Fragment>*/}
//             {/*          ),*/}
//             {/*        }}*/}
//             {/*      />*/}
//             {/*    )}*/}
//             {/*  />*/}
//             {/*</Grid>*/}
//             <Grid item xs={0.5}>
//               <IconButton
//                 color='secondary'
//                 onClick={() => handleRemoveForm(index)}
//               >
//                 <ClearIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         </Paper>);
//       })}
//       <IconButton color='primary' onClick={handleAddForm}>
//         <AddIcon />
//       </IconButton>
//     </div>
//   );
// });


export default TagsInput;
