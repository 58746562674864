import {
useTheme
} from 'react-admin';
import { Chip } from '@material-ui/core';
import SimpleList from './RKList';
import React from 'react';

const AppPanel = ({ id, record, resource }: any) => {
  const [theme, ] = useTheme();

  return (
    <div>
      {record.google_ads_rk.length > 0 &&
        <div style={{ marginTop: '20px' }}>
          <h3 style={{ color: theme === 'dark' ? 'lightgrey' : 'black' }} >Google ADS RK</h3>
          <span style={{ fontSize: '16px' }} >Communication identifier: </span>
          <Chip
            style={{
              fontSize: '16px',
              color: theme === 'dark' ? 'lightgrey' : 'black',
              backgroundColor: theme === 'dark' ? 'black' : ''
            }}
            label={record.google_ads_identifier}
            variant="outlined"
          />
          <SimpleList theme={theme} items={record.google_ads_rk} />
        </div>
      }
    </div>
  )
};

export default AppPanel;
