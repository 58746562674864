import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'

const AccountEdit = (props: any) => {
  return (
    <Edit
      title='Edit Account'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <TextInput source='login' />
        <TextInput source='proxy' fullWidth resettable />
        <TextInput source='cookies' resettable multiline fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export default AccountEdit
