import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  CheckboxGroupInput,
  Edit,
  ListButton,
  CloneButton,
  SimpleFormIterator,
  TextInput,
  TimeInput,
  TopToolbar,
  useGetList,
  DateTimeInput,
  SelectInput,
  TabbedForm,
  RadioButtonGroupInput,
  useGetRecordId, SaveButton, Toolbar, useNotify, useRedirect,
} from 'react-admin';
import {
  Alert, AlertTitle,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { Grid } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { pushLanguages } from '../../data/pushLanguages';
import { TabsContext, FormTabs } from './TabForm';
import TagsInput from './TagsInput';
import { fetchApps, fetchCampaignById } from './pushHelpers';
import { API_URL } from '../../App';

const CampaignEditActions = () => (
  <TopToolbar>
    <ListButton />
    <CloneButton />
  </TopToolbar>
);

const CampEditToolBar = () => (
  <Toolbar>
    <SaveButton alwaysEnable />
    {/*<DeleteButton sx={{ position: 'absolute', right: '0', mr: '20px' }} />*/}
  </Toolbar>
);

export const CampaignEdit = (props: any) => {
  const [apps, setApps] = useState([]);
  const [geoOptions, setGeoOptions] = useState([]);
  const [appGroups, setAppGroups] = useState([]);
  const [currTabs, setCurrTabs] = useState([]);
  const [currTags, setCurrTags] = useState([]);
  const [tagsLoad, setTagsLoad] = useState(true)
  const recordId = useGetRecordId();

  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (values: any) => {
    values.target.tags = currTags;
    values.payload = currTabs;

    fetch(`${API_URL}/push-service/campaigns/${recordId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.id) {
          notify(
            <Alert severity='success' variant='filled'>
              <AlertTitle>Success</AlertTitle>
              Campaign edited!
            </Alert>,
            {
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
          setTimeout(() => {
            // redirect('http://localhost:3000/#/push-service/campaigns');
            redirect('https://mirapanelapps.com/#/push-service/campaigns');
          }, 800);
        } else {
          notify(
            <Alert severity='error' variant='filled'>
               <AlertTitle>Failed!</AlertTitle>
               Campaign edit failed, check your incoming data and try again!
             </Alert>,
            {
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        }
      })
      .catch(error => {
        notify(
          <Alert severity='error' variant='filled'>
             <AlertTitle>Failed</AlertTitle>
             Campaign edit failed, check your incoming data and try again!
           </Alert>,
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          });
      });
  };

  const appGroupsList: any = useGetList(
    'appGroups',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  const geosList: any = useGetList(
    'geosArray',
  );

  useEffect(() => {
    if (appGroupsList.data) {
      setAppGroups(appGroupsList.data?.filter((gorup: any) => gorup.isEnabled)
        .map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appGroupsList.data]);

  useEffect(() => {
    if (geosList.data) {
      setGeoOptions(geosList.data?.map((record: any) => ({ id: record.code.toLowerCase(), name: record.countryEn })));
    }
  }, [geosList.data]);

  useEffect(() => {
    const hash = window.location.hash;
    const decodedURL = decodeURIComponent(hash);

    const match = decodedURL.match(/\/(\d+)$/);
    const campaignId = match ? match[1] : null;

    const fetchDataFromApi = async () => {
      const osApps = await fetchApps();
      setApps(osApps.map((osApp: any) => ({ id: osApp.id, name: osApp.name })));

      if (campaignId) {
        const campData = await fetchCampaignById(campaignId)
        if (campData) {
          if (campData.target) {
            setCurrTags(campData.target.tags);
          }
        }
        setTagsLoad(false)
      }
    };
    fetchDataFromApi();
  }, []);

  const handleTabsChange: any = useCallback((updatedTabs: any) => {
    setCurrTabs(updatedTabs)
  }, []);

  const handleTagsChange = useCallback((updatedTags: any) => {
    setCurrTags(updatedTags)
  }, []);

  return (
    <Edit
      title='Edit Campaign'
      mutationMode='pessimistic'
      actions={<CampaignEditActions />}
      {...props}
    >
      <CardContent>
        <TabbedForm
          toolbar={<CampEditToolBar/>}
          onSubmit={handleSubmit}
        >
          <TabbedForm.Tab
            label='General Info'
          >
            <Grid container sx={{ pl: '30px', pr: '30px' }}>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }} >
                  <TextInput source='name' label='Campaign name' fullWidth />
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }} >
                  <AutocompleteInput
                    source='enabled'
                    choices={[
                      { id: true, name: 'Enabled' },
                      { id: false, name: 'Disabled' },
                    ]}
                    label='Status'
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>

                <Grid item xs={6} sx={{ display: 'flex' }} >
                  <AutocompleteArrayInput
                    source='target.app_ids'
                    label='Applications'
                    choices={[...apps, ...appGroups]}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }} >
                  <AutocompleteArrayInput
                    source='target.country'
                    label='GEO'
                    choices={geoOptions}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <AutocompleteArrayInput
                    source='target.langs'
                    label='Languages'
                    choices={pushLanguages}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <SelectInput
                    label='Event'
                    source='event'
                    choices={[
                      { id: 'com', name: 'Common' },
                      { id: 'reg', name: 'Registration' },
                      { id: 'dep', name: 'Deposit' },
                      { id: 'inst', name: 'Install' },
                    ]}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div>
                {/*<Grid item xs={12}>*/}
                {/*  <SelectInput source="target.taglogic" choices={[*/}
                {/*    { id: 'and', name: 'AND' },*/}
                {/*    { id: 'or', name: 'OR' },*/}
                {/*  ]} />*/}
                {/*</Grid>*/}

                {/*{tagsLoad && (*/}
                {/*  <Grid item xs={12} sx={{ zIndex: 1, display: 'flex', pl: '100px', mb: '10px' }}>*/}
                {/*    <Grid item xs={6} sx={{ mr: 'auto', mt: '10px' }} >*/}
                {/*      <CircularProgress color='inherit' />*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
                {/*)}*/}
                {/*{!tagsLoad &&*/}
                {/*  (*/}
                {/*    <TagsInput initialForms={currTags} onChange={handleTagsChange} />*/}
                {/*  )*/}
                {/*}*/}
              </div>

              <div>
                {/*{!open && (*/}
                {/*  <Grid item xs={12} sx={{ display: 'flex', mb: '20px' }}>*/}
                {/*    <ArrayInput*/}
                {/*      onClick={() => {}}*/}
                {/*      source="target.tags"*/}
                {/*      label='Add parameters'*/}
                {/*    >*/}
                {/*      <SimpleFormIterator disableReordering inline>*/}
                {/*        <AutocompleteInput*/}
                {/*          sx={{ width: '270px', mr: '10px' }}*/}
                {/*          source='tag'*/}
                {/*          label='Select tag'*/}
                {/*          choices={tags}*/}
                {/*        />*/}
                {/*        <FormDataConsumer>*/}
                {/*          {({ scopedFormData, getSource, ...rest }: any) => {*/}
                {/*            let variants: any[];*/}
                {/*            // @ts-ignore*/}
                {/*            if (scopedFormData.tag) {*/}
                {/*              // @ts-ignore*/}
                {/*              variants = tagsValues[scopedFormData.tag].map((sub: string, index: number) => ({ id: sub, name: sub }));*/}
                {/*            } else {*/}
                {/*              variants = [];*/}
                {/*            }*/}
                {/*            return (*/}
                {/*              <AutocompleteInput*/}
                {/*                sx={{ width: '120vh' }}*/}
                {/*                label='Select option'*/}
                {/*                source={getSource('value')}*/}
                {/*                choices={variants}*/}
                {/*                {...rest}*/}
                {/*              />*/}
                {/*            );*/}
                {/*          }}*/}
                {/*        </FormDataConsumer>*/}
                {/*      </SimpleFormIterator>*/}
                {/*    </ArrayInput>*/}
                {/*  </Grid>*/}
                {/*)}*/}

              </div>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  columnGap: '30px',
                  padding: '15px',
                  borderBottom: '5px solid rgb(21, 150, 243)',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  backgroundColor: 'rgba(185,229,239,0.3)',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                  mb: '20px'
                }}
              >
                <Grid item xs={5} sx={{ display: 'flex' }}>
                  <CheckboxGroupInput
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        marginRight: '5px',
                        marginLeft: '5px',
                      },
                    }}
                    source="target.days"
                    label='Days of repeating the task'
                    choices={[
                      { id: 'monday', name: 'Mon' },
                      { id: 'tuesday', name: 'Tue' },
                      { id: 'wednesday', name: 'Wed' },
                      { id: 'thursday', name: 'Thu' },
                      { id: 'friday', name: 'Fri' },
                      { id: 'saturday', name: 'Sat' },
                      { id: 'sunday', name: 'Sun' },
                    ]}
                    labelPlacement="top"
                    options={{
                      icon: <CheckBoxOutlineBlankIcon fontSize='medium' />,
                      checkedIcon: <CheckBoxIcon fontSize='medium' />
                    }}
                  />
                  {/*<BooleanInput*/}
                  {/*  label='Send by local user time'*/}
                  {/*  source='target.usertz'*/}
                  {/*  sx={{ mt: '50px', ml: '20px' }}*/}
                  {/*/>*/}
                </Grid>
                <Grid item xs={3}>
                  <ArrayInput source='target.time' label='Time' sx={{ mt: '15px' }}>
                    <SimpleFormIterator
                      disableReordering
                      getItemLabel={index => `#${index + 1}`}
                      sx={{
                        '& .RaSimpleFormIterator-line': {
                          borderBottom: '0',
                          marginTop: '0px'
                        },
                        '& .ra-input': {
                          marginTop: '0px'
                        },
                      }}
                    >
                      <TimeInput
                        sx={{ mt: '39px', width: '300px' }}
                        source=''
                        label='Task repeat time'
                        parse={(value) => (value)}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <Grid item xs={3}>
                  <DateTimeInput
                    source="end_date"
                    sx={{ mt: '38px', width: '350px' }}
                    // parse={(v) => {
                    //   console.log(v);
                    //   if (!v) return '';
                    //
                    //   const date = new Date(v);
                    //   const year = date.getFullYear();
                    //   const month = String(date.getMonth() + 1).padStart(2, '0');
                    //   const day = String(date.getDate()).padStart(2, '0');
                    //   const hours = String(date.getHours()).padStart(2, '0');
                    //   const minutes = String(date.getMinutes()).padStart(2, '0');
                    //   const seconds = String(date.getSeconds()).padStart(2, '0');
                    //   const offset = -date.getTimezoneOffset() / 60;
                    //
                    //   return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offset >= 0 ? '+' : '-'}${String(offset).padStart(2, '0')}:00`;
                    // }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TabbedForm.Tab>
          <div>
            {/*<TabbedForm.Tab label='Variants'>*/}
            {/*  <RadioButtonGroupInput source="payload_order" choices={[*/}
            {/*    { id: 'rotation', name: 'Rotation' },*/}
            {/*    { id: 'random', name: 'Random' },*/}
            {/*  ]} />*/}
            {/*  <ArrayInput*/}
            {/*    source="payload"*/}
            {/*    label='Variants:'*/}
            {/*  >*/}
            {/*    <SimpleFormIterator*/}
            {/*      disableReordering*/}
            {/*      getItemLabel={index => `#${index + 1}`}*/}
            {/*      sx={{*/}
            {/*        paddingTop: '15px',*/}
            {/*        '& .RaSimpleFormIterator-form': {*/}
            {/*          width: '100%',*/}
            {/*          display: 'grid',*/}
            {/*          gridTemplateColumns: '3fr 3fr',*/}
            {/*          columnGap: '30px',*/}
            {/*          rowGap: '0px'*/}
            {/*        },*/}
            {/*        '& .RaSimpleFormIterator-line': {*/}
            {/*          paddingLeft: '15px',*/}
            {/*          paddingTop: '25px',*/}
            {/*          paddingBottom: '5px',*/}
            {/*          marginBottom: '30px',*/}
            {/*          backgroundColor: 'rgba(185,229,239,0.3)',*/}
            {/*          borderTopLeftRadius: '5px',*/}
            {/*          borderTopRightRadius: '5px',*/}
            {/*          boxShadow: '5px -5px 10px rgba(0, 0, 0, 0.3)',*/}
            {/*        },*/}
            {/*        '& .RaSimpleFormIterator-index': {*/}
            {/*          fontSize: '16px',*/}
            {/*          color: 'rgb(9,131,220)',*/}
            {/*          fontWeight: '500',*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <TextInput*/}
            {/*        label='Title'*/}
            {/*        source="title"*/}
            {/*        helperText={false}*/}
            {/*        fullWidth />*/}
            {/*      <BooleanInput*/}
            {/*        label="Enable"*/}
            {/*        source="enabled"*/}
            {/*      />*/}
            {/*      <TextInput*/}
            {/*        label='Icon URL'*/}
            {/*        source="icon_url"*/}
            {/*        helperText={false}*/}
            {/*        fullWidth*/}
            {/*      />*/}
            {/*      <TextInput*/}
            {/*        label='Image URL'*/}
            {/*        source="logo_url"*/}
            {/*        helperText={false}*/}
            {/*        fullWidth*/}
            {/*      />*/}
            {/*      <ArrayInput sx={{ gridColumn: 'span 2' }} source="content" label='Content:'>*/}
            {/*        <SimpleFormIterator*/}
            {/*          disableReordering*/}
            {/*          getItemLabel={index => `LANG${index + 1}`}*/}
            {/*          sx={{*/}
            {/*            paddingTop: '15px',*/}
            {/*            '& .RaSimpleFormIterator-form': {*/}
            {/*              width: '100%',*/}
            {/*              display: 'grid',*/}
            {/*              gridTemplateColumns: '1fr 9fr',*/}
            {/*              columnGap: '20px',*/}
            {/*              rowGap: '0px',*/}
            {/*            },*/}
            {/*            '& .RaSimpleFormIterator-line': {*/}
            {/*              // width: '100%',*/}
            {/*              paddingLeft: '0px',*/}
            {/*              paddingTop: '0px',*/}
            {/*              paddingBottom: '0px',*/}
            {/*              marginBottom: '0px',*/}
            {/*              backgroundColor: 'transparent',*/}
            {/*              borderTopLeftRadius: '0',*/}
            {/*              borderTopRightRadius: '0',*/}
            {/*              boxShadow: '0',*/}
            {/*            },*/}
            {/*            '& .RaSimpleFormIterator-index': {*/}
            {/*              // fontSize: '100px',*/}
            {/*              color: 'rgb(9,131,220)',*/}
            {/*              fontWeight: '500',*/}
            {/*              pt: '15px',*/}
            {/*            },*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <AutocompleteInput*/}
            {/*            sx={{ gridColumn: '1' }}*/}
            {/*            source='lang'*/}
            {/*            label='Select language'*/}
            {/*            choices={pushLanguages}*/}
            {/*          />*/}
            {/*          <TextInput*/}
            {/*            label='Header'*/}
            {/*            source='header'*/}
            {/*            helperText={false}*/}
            {/*          />*/}
            {/*          <TextInput*/}
            {/*            sx={{ gridColumn: '2' }}*/}
            {/*            source='message'*/}
            {/*            label='Message'*/}
            {/*            // rows={3}*/}
            {/*            multiline*/}
            {/*            resettable*/}
            {/*          />*/}
            {/*        </SimpleFormIterator>*/}
            {/*      </ArrayInput>*/}
            {/*    </SimpleFormIterator>*/}
            {/*  </ArrayInput>*/}

            {/*</TabbedForm.Tab>*/}
          </div>


          <TabbedForm.Tab label='TAGS'>
            <Grid item xs={12}>
              <SelectInput source="target.taglogic" choices={[
                { id: 'and', name: 'AND' },
                { id: 'or', name: 'OR' },
              ]} />
            </Grid>

            {tagsLoad && (
              <Grid item xs={12} sx={{ zIndex: 1, display: 'flex', pl: '100px', mb: '10px' }}>
                <Grid item xs={6} sx={{ mr: 'auto', mt: '10px' }} >
                  <CircularProgress color='inherit' />
                </Grid>
              </Grid>
            )}

            {!tagsLoad && (
              <TagsInput
                { ...props }
                // initialTags={currTags}
                onChange={handleTagsChange}
              />
            )}
          </TabbedForm.Tab>

          <TabbedForm.Tab label='VARIANTS'>
            <RadioButtonGroupInput label='Push order' source="payload_order" choices={[
              { id: 'rotation', name: 'Rotation' },
              { id: 'random', name: 'Random' },
            ]} />

            {/*{!tabsLoad && (*/}
              <TabsContext.Provider value={{ handleTabsChange } as any}>
                <FormTabs
                  { ...props }
                  // tabs={currTabs}
                  // onChange={handleTabsChange}
                />
              </TabsContext.Provider>
            {/*)}*/}

          </TabbedForm.Tab>

        </TabbedForm>
      </CardContent>
    </Edit>
  );
}
