import {
  Form,
  TextInput,
  SaveButton,
  Title,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  CheckboxGroupInput,
  TimeInput, useGetList, useNotify,
} from 'react-admin';
import { Alert, AlertTitle, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { transformArray } from './MomentarySending';
import AutocompleteWithSelectAllMixin from '../apps/AutoComplete';
import { pushLanguages } from '../../data/pushLanguages';

export const AutomaticSending = () => {
  const [filters, setFilters] = useState({});
  const [firstFiltersInput, setFirstFiltersInput] = useState([]);
  const [apps, setApps] = useState([]);
  const [geo, setGeo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [geos, setGeos] = useState([]);
  const [langs, setLangs] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null);

  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetch('https://mirapanelapps.com/backend/push-service/getFilter', {
    // fetch(`${process.env.REACT_APP_API_URL}/push/getFilter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
      })
    })
      .then(response => response.json())
      .then(data => {
        setFilters(data);
        const filtersValue = Object.keys(data);
        // @ts-ignore
        setFirstFiltersInput(filtersValue.map((record: any) => ({ id: record, name: record })));
        handleClose();
      })
      .catch(error => {
        handleClose();
        console.error('Error during query execution:', error);
      });
  }, []);

  const appList: any = useGetList(
    'appsList',
  );

  const geosList: any = useGetList(
    'geosArray',
  );

  useEffect(() => {
    if (geosList.data) {
      setGeo(geosList.data?.map((record: any) => ({ id: record.code, name: record.countryEn })));
    }
  }, [geosList.data]);

  useEffect(() => {
    if (appList.data) {
      setApps(appList.data?.map((record: any) => ({ ...record, label: record.name })));
    }
  }, [appList.data]);

  const handleSubmit = (values: any) => {
    const data = {
      name: values.name,
      app_id: values.app,
      status: values.status,
      event: values.event,
      country: geos.map((geo: any) => (geo.id)) || ['all'],
      language: langs.map((lang: any) => (lang.id)) || ['all'],
      time: values.time.map((value: any) => (`${value.toString().substring(16, 18)}:${value.toString().substring(19, 21)}`)),
      days: values.days,
      delayed: values.delayed,
      or: values.or,
      excludeTag: values.excludeTag,
      additionalPush: values.randomOrder,
      params: transformArray(values.params),
      variants: values.variants.map((variant: any) => ({ ...variant, sent: {} })),
    }

    fetch('https://mirapanelapps.com/backend/push-service/send/auto', {
    // fetch(`${process.env.REACT_APP_API_URL}/push/send/auto`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        notify(
          <Alert severity='success' variant='filled'>
            <AlertTitle>Success</AlertTitle>
            Push sent!
          </Alert>,
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          });
      })
      .catch(error => {
      });
  };

  return (
    <>
      <Card>
        <Title title='Automatic Sending' />
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Grid container sx={{ pl: '30px', pr: '30px' }}>
              <Grid item xs={12}>
                <TextInput source='name' label='Task name' fullWidth />
              </Grid>

              <Grid item xs={12}>
                <AutocompleteInput
                  source='status'
                  choices={[
                    { id: 'Активно', name: 'Активно' },
                    { id: 'Остановлено', name: 'Остановлено' },
                  ]}
                  label='Status'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }} >
                  <BooleanInput
                    label='Exclude Apps'
                    source='excludeApp'
                    sx={{ mt: '15px', mr: '1px', minWidth: '190px' }}
                  />
                  <AutocompleteArrayInput
                    source='app'
                    label='Applications'
                    choices={[
                      { id: 'allApps', name: 'All Apps'},
                      { id: 'allAviator', name: 'All Aviator'},
                      { id: 'allBetting', name: 'All Betting'},
                      { id: 'allGambling', name: 'All Gambling'},
                      ...apps
                    ]}
                    fullWidth
                  />
                </Grid>
                {/*<Typography*/}
                {/*  variant="subtitle1"*/}
                {/*  gutterBottom*/}
                {/*  sx={{ mt: '41px', mr: '10px' }}*/}
                {/*>*/}
                {/*  Include Apps*/}
                {/*</Typography>*/}
                <Grid item xs={6} sx={{ display: 'flex' }} >
                  <AutocompleteWithSelectAllMixin
                    multiple
                    fullWidth
                    options={[
                      { id: 'all', name: 'All GEO' },
                      ...geo
                    ] as any}
                    setValue={setGeos}
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    value={geos}
                    renderInput={(params: any) => <TextField {...params} label={"GEO"} />}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ mt: '41px', color: '#666666', mr: '15px' }}
                  >
                    Parameters:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ mt: '41px', mr: '10px' }}
                  >
                    AND
                  </Typography>
                  <BooleanInput
                    label='OR'
                    source='or'
                    sx={{ mt: '36px', mr: '20px' }}
                  />
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ mt: '41px', mr: '10px' }}
                  >
                    Include Tag
                  </Typography>
                  <BooleanInput
                    label='Exclude Tag'
                    source='excludeTag'
                    sx={{ mt: '36px', mr: '20px' }}
                  />

                </Grid>

                <Grid item xs={6} sx={{ mt: '22px' }}>
                  <AutocompleteWithSelectAllMixin
                    multiple
                    fullWidth
                    options={[
                      { id: 'all', name: 'All languages' },
                      ...pushLanguages
                    ] as any}
                    setValue={setLangs}
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    value={langs}
                    renderInput={(params: any) => <TextField {...params} label={"Languages"} />}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <ArrayInput onClick={() => {
                  if (firstFiltersInput.length === 0) {
                    handleOpen();
                  }
                }} source="params" label='Add parameters'>
                  <SimpleFormIterator disableReordering inline>
                    <AutocompleteInput
                      sx={{ width: '270px', mr: '10px' }}
                      source='firstInput'
                      label='Select parameter'
                      choices={firstFiltersInput}
                      onChange={(event, newValue: any) => setSelectedParameter(newValue)}
                    />
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }: any) => {
                        let variants: any[];
                        // @ts-ignore
                        if (scopedFormData.firstInput && filters[scopedFormData.firstInput]) {
                          // @ts-ignore
                          variants = filters[scopedFormData.firstInput].map((sub: string, index: number) => ({ id: sub, name: sub }));
                        } else {
                          variants = [];
                        }
                        return (
                          <AutocompleteInput
                            sx={{ minWidth: '480px' }}
                            label='Select option'
                            source={getSource('secondInput')}
                            choices={variants}
                            {...rest}
                          />
                        )
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ mt: '0px' }}>
                  <AutocompleteInput
                    source='event'
                    label='An event that occurred'
                    choices={[
                      { id: 'none', name: 'Without event' },
                      { id: 'install', name: 'Install' },
                      { id: 'registration', name: 'Registration' },
                      { id: 'deposit', name: 'Deposit' },
                    ]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: '0px', display: 'flex' }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ mt: '18px', mr: '10px' }}
                  >
                    In order
                  </Typography>
                  <BooleanInput
                    label='Randomly'
                    source='randomOrder'
                    sx={{ mt: '13px' }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{
                display: 'flex',
                columnGap: '30px',
                // paddingLeft: '15px',
                // paddingTop: '15px',
                padding: '15px',
                borderBottom: '5px solid rgb(21, 150, 243)',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                backgroundColor: 'rgba(185,229,239,0.3)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
              }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <CheckboxGroupInput
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        marginRight: '5px',
                        marginLeft: '5px',
                      },
                    }}
                    source="days"
                    label='Days of repeating the task'
                    choices={[
                      { id: 'monday', name: 'Mon' },
                      { id: 'tuesday', name: 'Tue' },
                      { id: 'wednesday', name: 'Wed' },
                      { id: 'thursday', name: 'Thu' },
                      { id: 'friday', name: 'Fri' },
                      { id: 'saturday', name: 'Sat' },
                      { id: 'sunday', name: 'Sun' },
                    ]}
                    labelPlacement="top"
                    options={{
                      icon: <CheckBoxOutlineBlankIcon fontSize='medium' />,
                      checkedIcon: <CheckBoxIcon fontSize='medium' />
                    }}
                  />
                  <BooleanInput
                    label='Send by local user time'
                    source='delayed'
                    sx={{ mt: '50px', ml: '20px' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ArrayInput source='time' label='Time'>
                    <SimpleFormIterator
                      disableReordering
                      getItemLabel={index => `#${index + 1}`}
                      sx={{
                        '& .RaSimpleFormIterator-line': {
                          borderBottom: '0',
                          marginTop: '0px'
                        },
                        '& .ra-input': {
                          marginTop: '0px'
                        },
                      }}
                    >
                      <TimeInput
                        sx={{ mt: '39px', width: '300px' }}
                        source=''
                        label='Task repeat time'
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
              </Grid>

              <ArrayInput source="variants" label='Push variants' sx={{ mt: '25px' }}>
                <SimpleFormIterator
                  disableReordering
                  getItemLabel={index => `#${index + 1}`}
                  sx={{
                    paddingTop: '15px',
                    '& .RaSimpleFormIterator-form': {
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: '30px',
                      rowGap: '10px'
                    },
                    '& .RaSimpleFormIterator-line': {
                      paddingLeft: '15px',
                      paddingTop: '25px',
                      paddingBottom: '5px',
                      marginBottom: '30px',
                      backgroundColor: 'rgba(185,229,239,0.3)',
                      borderTopLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                      boxShadow: '5px -5px 10px rgba(0, 0, 0, 0.3)',
                    },
                    '& .RaSimpleFormIterator-index': {
                      fontSize: '16px',
                      color: 'rgb(9,131,220)',
                      fontWeight: '500',
                    }
                  }}
                >
                  <TextInput label='Caption' source="title" helperText={false} fullWidth />
                  <TextInput label='Image URL' source="logo_url" helperText={false} fullWidth />
                  <TextInput label='Icon URL' source="icon_url" helperText={false} fullWidth />
                  <TextInput label='Link when opening' source="link" helperText={false} fullWidth />
                  <TextInput
                    sx={{ width: '100%', gridColumn: '1 / span 2' }}
                    source='message'
                    label='Message'
                    rows={3}
                    fullWidth
                    multiline
                    resettable
                  />
                </SimpleFormIterator>
              </ArrayInput>

              <Grid item xs={12} sx={{ mt: '20px' }}>
                <SaveButton label='Send' />
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>

      <Box sx={{ zIndex: 1 }}>
        <Backdrop
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </Box>
    </>
  );
};

