import React from 'react';
import { CreateButton, SelectColumnsButton, TopToolbar } from 'react-admin';
import { ListViewButton } from './ListViewButton';

const AllAppsListActions = () => {
  return (
    <>
      <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <CreateButton variant='contained' />
        {/*<ListViewButton />*/}
        {/*<FilterButton />*/}
      </TopToolbar>
    </>
  );
};

export default AllAppsListActions;
