import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator, TabbedForm,
  TextInput, TimeInput,
  useGetList,
  useNotify,
} from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { CardContent, Grid, TextField, Alert, AlertTitle, Typography } from '@mui/material';
import AutocompleteWithSelectAllMixin from '../apps/AutoComplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { pushLanguages } from '../../data/pushLanguages';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useRedirect } from 'react-admin';
import { API_URL } from '../../App';
import { fetchApps, fetchTags, fetchTagValues } from './pushHelpers';
import TagsInput from './TagsInput';
import { FormTabs, TabsContext } from './TabForm';


function formatDateWithTimezone(date: any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const timezoneOffset = -date.getTimezoneOffset();
  const timezoneOffsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const timezoneOffsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
  const timezoneSign = timezoneOffset >= 0 ? '+' : '-';

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
}

export const CreateCampaign = (props: any) => {
  const [tags, setTags] = useState([]);
  const [apps, setApps] = useState([]);
  const [tagsValues, setTagsValues] = useState({});

  const [prevDate, setPrevDate] = useState(null);
  const [open, setOpen] = useState(true);
  const [langs, setLangs] = useState([]);

  const [geos, setGeos] = useState([]);
  const [geoOptions, setGeoOptions] = useState([]);

  const [tagLogic, setTagLogic] = React.useState('and');
  const [sendOrder, setSendOrder] = useState('rotation');

  const [appGroups, setAppGroups] = useState([]);

  const [tagsToSend, setTagsToSend] = useState([]);

  const [tagsLoad, setTagsLoad] = useState(true)
  const [currTags, setCurrTags] = useState([]);
  const [currTabs, setCurrTabs] = useState([{
    id: 1,
    title: 'Push 1',
    icon_url: '',
    logo_url: '',
    created: '',
    enabled: true,
    archived: false,
    content: [{ id: 1, lang: 'en', message: '', header: '' }],
  }]);

  const redirect = useRedirect();
  const notify = useNotify();

  const handleChangeTagLogic = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagLogic((event.target as HTMLInputElement).value);
  };

  const handleChangeSendOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendOrder((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    handleOpen()

    const hash = window.location.hash;
    const decodedURL = decodeURIComponent(hash);

    if (decodedURL.includes('source')) {
      const params = decodedURL.split('?')[1];
      const sourceParam = params.slice(7);
      const decodedCampaignData = JSON.parse(decodeURIComponent(sourceParam));

      setPrevDate(decodedCampaignData.end_date);
      const tagsArr = decodedCampaignData.target.tags.map((item: any) => ({ id: item.tag, name: item.tag }));

      setTags(tagsArr);

      const tagsWithValues = decodedCampaignData.target.tags.reduce((acc: any, { tag, value }: any) => {
        if (!acc[tag]) {
          acc[tag] = [value];
        } else {
          acc[tag].push(value);
        }
        return acc;
      }, {});

      setTagsValues(tagsWithValues);

      setGeos(decodedCampaignData.target.country.map((geo: string) => ({ id: geo, name: geo.toUpperCase() })));
      setLangs(decodedCampaignData.target.langs.map((lang: string) => ({ id: lang, name: lang.toUpperCase() })));
    }

    const fetchDataFromApi = async () => {
      const osApps = await fetchApps();
      // const osTags = await fetchTags();

      setApps(osApps.map((osApp: any) => ({ id: osApp.id, name: osApp.name })));
      // setTags(osTags.payload.sort().map((osTag: string) => ({ id: osTag, name: osTag })));

      // const tags: any = osTags.payload;
      // const dataObject: any = {};
      //
      // await Promise.all(
      //   tags.map(async (tag: any) => {
      //     const values = await fetchTagValues(tag);
      //     dataObject[tag] = values.payload;
      //   })
      // );
      //
      // setTagsValues(dataObject);
      handleClose();
    };
    fetchDataFromApi();
  }, []);

  const geosList: any = useGetList(
    'geosArray',
  );

  const appGroupsList: any = useGetList(
    'appGroups',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (appGroupsList.data) {
      setAppGroups(appGroupsList.data?.filter((gorup: any) => gorup.isEnabled)
        .map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appGroupsList.data]);

  useEffect(() => {
    if (geosList.data) {
      setGeoOptions(geosList.data
        ?.map((record: any) => ({ id: record.code.toLowerCase(), name: record.countryEn })));
    }
  }, [geosList.data]);

  const handleSubmit = (values: any) => {
    // if (prevDate && prevDate !== values.end_date) {
    //   values.end_date = formatDateWithTimezone(values.end_date);
    // } else {
    //   const endDate = new Date(values.end_date);
    //   values.end_date = formatDateWithTimezone(endDate);
    // }
    values.end_date = new Date(values.end_date).toISOString();
    values.start_date = "2024-02-15T22:00:00Z";
    values.tags = [];
    values.target.langs = langs.map((lang: any) => (lang.id));
    values.target.country = geos.map((geo: any) => (geo.id ));
    values.target.taglogic = tagLogic;
    values.target.delayed = true;
    values.target.time = values.target.time.map((time: any) => {
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (timeRegex.test(time)) {
        return time;
      } else {
        const parsedTime = new Date(time);
        return parsedTime.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
      }
    });
    values.payload_order = sendOrder;
    values.target.tags = tagsToSend;
    values.payload = currTabs;

    fetch(`${API_URL}/push-service/campaigns`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.success) {
          notify(
            <Alert severity='success' variant='filled'>
              <AlertTitle>Success</AlertTitle>
                Campaign saved!
             </Alert>,
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          });

           setTimeout(() => {
             // redirect('http://localhost:3000/#/push-service/campaigns');
             redirect('https://mirapanelapps.com/#/push-service/campaigns');
           }, 1000);
           } else {
             notify(
               <Alert severity='error' variant='filled'>
                 <AlertTitle>Failed!</AlertTitle>
                   Campaign creation failed, check your incoming data and try again!
                 </Alert>,
                {
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
           }
         })
      .catch(error => {
         notify(
           <Alert severity='error' variant='filled'>
              <AlertTitle>Failed</AlertTitle>
              Campaign creation failed, check your incoming data and try again!
            </Alert>,
            {
             anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
           });
       });
  };

  const handleTagsChange = useCallback((updatedTags: any) => {
    setTagsToSend(updatedTags)
  }, []);

  const handleTabsChange: any = useCallback((updatedTabs: any) => {
    setCurrTabs(updatedTabs)
  }, []);

  return (
    <Create>
      <CardContent>
        <TabbedForm onSubmit={handleSubmit}>
          <TabbedForm.Tab
            label='General Info'
          >
            <Grid container sx={{ pl: '30px', pr: '30px' }}>
              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <TextInput source='name' label='Campaign name' fullWidth isRequired />
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <AutocompleteInput
                    source='enabled'
                    choices={[
                      { id: true, name: 'Enabled' },
                      { id: false, name: 'Disabled' },
                    ]}
                    label='Status'
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <AutocompleteArrayInput
                    source='target.app_ids'
                    label='Applications'
                    choices={[...apps, ...appGroups]}
                    fullWidth
                    isRequired
                  />
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <AutocompleteWithSelectAllMixin
                    multiple
                    fullWidth
                    options={geoOptions}
                    setValue={setGeos}
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    value={geos}
                    renderInput={(params: any) => <TextField {...params} label={"GEO"} />}
                    isRequired
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <AutocompleteWithSelectAllMixin
                    multiple
                    fullWidth
                    options={pushLanguages}
                    setValue={setLangs}
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    value={langs}
                    renderInput={(params: any) => <TextField {...params} label={"Languages"} />}
                    isRequired
                  />
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <SelectInput
                    label='Event'
                    source='event'
                    choices={[
                      { id: 'com', name: 'Common' },
                      { id: 'reg', name: 'Registration' },
                      { id: 'dep', name: 'Deposit' },
                      { id: 'inst', name: 'Install' },
                    ]}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {/*<TextInput label='Icon URL' source="payload.icon_url" helperText={false} fullWidth />*/}
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {/*<TextInput label='Image URL' source="payload.logo_url" helperText={false} fullWidth />*/}
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Tags logic</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={tagLogic}
                      onChange={handleChangeTagLogic}
                    >
                      <FormControlLabel value="and" control={<Radio />} label="AND" />
                      <FormControlLabel value="or" control={<Radio />} label="OR" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div>
                {/*{open && (*/}
                {/*  <Grid item xs={12} sx={{ zIndex: 1, display: 'flex', mb: '20px' }}>*/}
                {/*    <Grid item xs={6} sx={{ mr: 'auto', mt: '10px' }} >*/}
                {/*      <CircularProgress color='inherit' />*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
                {/*)}*/}

                {/*{!open && (*/}
                {/*  <Grid item xs={12} sx={{ display: 'flex', mb: '20px' }}>*/}
                {/*      <ArrayInput*/}
                {/*        source="target.tags"*/}
                {/*        label='Add parameters'*/}
                {/*      >*/}
                {/*        <SimpleFormIterator disableReordering inline>*/}
                {/*          <AutocompleteInput*/}
                {/*            sx={{ width: '270px', mr: '10px' }}*/}
                {/*            source='tag'*/}
                {/*            label='Select tag'*/}
                {/*            choices={tags}*/}
                {/*          />*/}
                {/*          <FormDataConsumer>*/}
                {/*            {({ scopedFormData, getSource, ...rest }: any) => {*/}
                {/*              let variants: any[];*/}
                {/*              // @ts-ignore*/}
                {/*              if (scopedFormData.tag) {*/}
                {/*                // @ts-ignore*/}
                {/*                variants = tagsValues[scopedFormData.tag].map((sub: string, index: number) => ({*/}
                {/*                  id: sub,*/}
                {/*                  name: sub*/}
                {/*                }));*/}
                {/*              } else {*/}
                {/*                variants = [];*/}
                {/*              }*/}
                {/*              return (*/}
                {/*                <AutocompleteInput*/}
                {/*                  sx={{ width: '120vh' }}*/}
                {/*                  label='Select option'*/}
                {/*                  source={getSource('value')}*/}
                {/*                  choices={variants}*/}
                {/*                  {...rest}*/}
                {/*                />*/}
                {/*              );*/}
                {/*            }}*/}
                {/*          </FormDataConsumer>*/}
                {/*        </SimpleFormIterator>*/}
                {/*      </ArrayInput>*/}
                {/*    /!*<Tags tags={[{ tag: '', value: '' }]} onChange={handleTagsChange} />*!/*/}

                {/*  </Grid>*/}
                {/*)}*/}
              </div>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  columnGap: '30px',
                  padding: '15px',
                  borderBottom: '5px solid rgb(21, 150, 243)',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  backgroundColor: 'rgba(185,229,239,0.3)',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                  mb: '20px'
                }}
              >
                <Grid item xs={5} sx={{ display: 'flex' }}>
                  <CheckboxGroupInput
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        marginRight: '5px',
                        marginLeft: '5px',
                      },
                    }}
                    source="target.days"
                    label='Days of repeating the task'
                    choices={[
                      { id: 'monday', name: 'Mon' },
                      { id: 'tuesday', name: 'Tue' },
                      { id: 'wednesday', name: 'Wed' },
                      { id: 'thursday', name: 'Thu' },
                      { id: 'friday', name: 'Fri' },
                      { id: 'saturday', name: 'Sat' },
                      { id: 'sunday', name: 'Sun' },
                    ]}
                    labelPlacement="top"
                    options={{
                      icon: <CheckBoxOutlineBlankIcon fontSize='medium' />,
                      checkedIcon: <CheckBoxIcon fontSize='medium' />
                    }}
                  />
                  {/*<BooleanInput*/}
                  {/*  label='Send by local user time'*/}
                  {/*  source='target.usertz'*/}
                  {/*  sx={{ mt: '50px', ml: '20px' }}*/}
                  {/*/>*/}
                </Grid>
                <Grid item xs={3}>
                  <ArrayInput source='target.time' label='Time' sx={{ mt: '15px' }}>
                    <SimpleFormIterator
                      disableReordering
                      getItemLabel={index => `#${index + 1}`}
                      sx={{
                        '& .RaSimpleFormIterator-line': {
                          borderBottom: '0',
                          marginTop: '0px'
                        },
                        '& .ra-input': {
                          marginTop: '0px'
                        },
                      }}
                    >
                      <TimeInput
                        sx={{ mt: '39px', width: '300px' }}
                        source=''
                        label='Task repeat time'
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <Grid item xs={3}>
                  <DateTimeInput
                    source="end_date"
                    sx={{ mt: '38px', width: '350px' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TabbedForm.Tab>

          <TabbedForm.Tab label='TAGS'>
            <Grid item xs={12}>
              <SelectInput source="target.taglogic" choices={[
                { id: 'and', name: 'AND' },
                { id: 'or', name: 'OR' },
              ]} />
            </Grid>

            <TagsInput
              { ...props }
              initialTags={currTags}
              onChange={handleTagsChange}
            />
          </TabbedForm.Tab>

          <TabbedForm.Tab
            label='Push Variants'
          >
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Send order:</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={sendOrder}
                onChange={handleChangeSendOrder}
              >
                <FormControlLabel value="rotation" control={<Radio />} label="Rotation" />
                <FormControlLabel value="random" control={<Radio />} label="Random" />
              </RadioGroup>
            </FormControl>

            <div>
              {/*<ArrayInput*/}
              {/*  source="payload"*/}
              {/*  label='Variants:'>*/}
              {/*  <SimpleFormIterator*/}
              {/*    disableReordering*/}
              {/*    getItemLabel={index => `#${index + 1}`}*/}
              {/*    sx={{*/}
              {/*      paddingTop: '15px',*/}
              {/*      '& .RaSimpleFormIterator-form': {*/}
              {/*        width: '100%',*/}
              {/*        display: 'grid',*/}
              {/*        gridTemplateColumns: '3fr 3fr',*/}
              {/*        columnGap: '30px',*/}
              {/*        rowGap: '0px'*/}
              {/*      },*/}
              {/*      '& .RaSimpleFormIterator-line': {*/}
              {/*        paddingLeft: '15px',*/}
              {/*        paddingTop: '25px',*/}
              {/*        paddingBottom: '5px',*/}
              {/*        marginBottom: '30px',*/}
              {/*        backgroundColor: 'rgba(185,229,239,0.3)',*/}
              {/*        borderTopLeftRadius: '5px',*/}
              {/*        borderTopRightRadius: '5px',*/}
              {/*        boxShadow: '5px -5px 10px rgba(0, 0, 0, 0.3)',*/}
              {/*      },*/}
              {/*      '& .RaSimpleFormIterator-index': {*/}
              {/*        fontSize: '16px',*/}
              {/*        color: 'rgb(9,131,220)',*/}
              {/*        fontWeight: '500',*/}
              {/*      }*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <TextInput*/}
              {/*      label='Title'*/}
              {/*      source="title"*/}
              {/*      helperText={false}*/}
              {/*      fullWidth />*/}
              {/*    <BooleanInput*/}
              {/*      label="Enable"*/}
              {/*      source="enabled"*/}

              {/*    />*/}
              {/*    <TextInput*/}
              {/*      label='Icon URL'*/}
              {/*      source="icon_url"*/}
              {/*      helperText={false}*/}
              {/*      fullWidth*/}
              {/*    />*/}
              {/*    <TextInput*/}
              {/*      label='Image URL'*/}
              {/*      source="logo_url"*/}
              {/*      helperText={false}*/}
              {/*      fullWidth*/}
              {/*    />*/}

              {/*    <ArrayInput sx={{gridColumn: 'span 2'}} source="content" label='Content:'>*/}
              {/*      <SimpleFormIterator*/}
              {/*        disableReordering*/}
              {/*        getItemLabel={index => `LANG${index + 1}`}*/}
              {/*        sx={{*/}
              {/*          paddingTop: '15px',*/}
              {/*          '& .RaSimpleFormIterator-form': {*/}
              {/*            width: '100%',*/}
              {/*            display: 'grid',*/}
              {/*            gridTemplateColumns: '1fr 9fr',*/}
              {/*            columnGap: '20px',*/}
              {/*            rowGap: '0px'*/}
              {/*          },*/}
              {/*          '& .RaSimpleFormIterator-line': {*/}
              {/*            // width: '100%',*/}
              {/*            paddingLeft: '0px',*/}
              {/*            paddingTop: '0px',*/}
              {/*            paddingBottom: '0px',*/}
              {/*            marginBottom: '0px',*/}
              {/*            backgroundColor: 'transparent',*/}
              {/*            borderTopLeftRadius: '0',*/}
              {/*            borderTopRightRadius: '0',*/}
              {/*            boxShadow: '0',*/}
              {/*          },*/}
              {/*          '& .RaSimpleFormIterator-index': {*/}
              {/*            // fontSize: '100px',*/}
              {/*            color: 'rgb(9,131,220)',*/}
              {/*            fontWeight: '500',*/}
              {/*            pt: '15px'*/}
              {/*          }*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <AutocompleteInput*/}
              {/*          sx={{ gridColumn: '1' }}*/}
              {/*          source='lang'*/}
              {/*          label='Select language'*/}
              {/*          choices={pushLanguages}*/}
              {/*        />*/}
              {/*        <TextInput*/}
              {/*          label='Header'*/}
              {/*          source="header"*/}
              {/*          helperText={false}*/}
              {/*        />*/}
              {/*        <TextInput*/}
              {/*          sx={{ gridColumn: '2' }}*/}
              {/*          source='message'*/}
              {/*          label='Message'*/}
              {/*          // rows={3}*/}
              {/*          multiline*/}
              {/*          resettable*/}
              {/*        />*/}
              {/*      </SimpleFormIterator>*/}
              {/*    </ArrayInput>*/}
              {/*  </SimpleFormIterator>*/}
              {/*</ArrayInput*/}
              {/*>*/}
            </div>
            <TabsContext.Provider value={{ currTabs, handleTabsChange } as any}>
              <FormTabs
                { ...props }
                // tabs={currTabs}
                // onChange={handleTabsChange}
              />
            </TabsContext.Provider>

          </TabbedForm.Tab>


          </TabbedForm>
      </CardContent>
    </Create>
  );
};
