import {
  AutocompleteInput,
  Filter,
  TextInput,
} from 'react-admin';
import React from 'react';

const CustomCampaignsFilter = (props: any) => {
  return (
    <Filter {...props} >
      <TextInput sx={{ minWidth: '250px' }} placeholder='Push name' source='name' resettable alwaysOn />

      <AutocompleteInput
        sx={{ minWidth: '250px' }}
        source='enabled'
        choices={[
          { id: true, name: 'Enabled' },
          { id: false, name: 'Disabled' },
        ]}
        label='Status'
        alwaysOn
      />

    </Filter>
  )
};

export default CustomCampaignsFilter;