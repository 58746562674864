import { useState } from 'react';
import Box from '@mui/material/Box';
import {
  MenuItemLink,
  useSidebarState,
} from 'react-admin';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SubMenu from './SubMenu';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CalculateIcon from '@mui/icons-material/Calculate';

type MenuName = 'menuPushNotifications' | 'menuPushNotifications2' | 'menuBot';

export const Menu = () => {
  const [state, setState] = useState({
    menuPushNotifications: false,
    menuPushNotifications2: false,
    menuBot: false,
  });

  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box sx={{
      width: open ? 300 : 50,
      marginTop: 1,
      marginBottom: 1,
    }}
    >
      <MenuItemLink
        to='/botApps'
        primaryText='Bot Apps'
        // leftIcon={<SportsEsportsIcon fontSize='medium' /> as any}
        leftIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
               className="bi bi-controller" viewBox="0 0 16 16">
            <path
              d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
            <path
              d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
          </svg>
        }
      />
      <MenuItemLink
        to='/app-rating'
        primaryText='Apps rating'
        leftIcon={<TrendingUpIcon fontSize='medium' /> as any}
      />
      <MenuItemLink
        to='/install-calculator'
        primaryText='Install calculator'
        leftIcon={<CalculateIcon fontSize='medium' /> as any}
      />
      <MenuItemLink
        to='/accounts'
        primaryText='Accounts'
        // leftIcon={<FacebookOutlinedIcon fontSize='medium' /> as any}
        leftIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-facebook"
               viewBox="0 0 16 16">
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
          </svg>
        }
      />
      <SubMenu
        handleToggle={() => handleToggle('menuBot')}
        isOpen={state.menuBot}
        name='Bot'
        icon={
          <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='currentColor' className='bi bi-robot'
               viewBox='0 0 16 16'>
            <path
              d='M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z' />
            <path
              d='M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z' />
          </svg>
        }
        dense={false}
      >
        <MenuItemLink
          to='/bot_messages'
          state={{ _scrollToTop: true }}
          primaryText='Send'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='/users'
          state={{ _scrollToTop: true }}
          primaryText='Users'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='/groups'
          state={{ _scrollToTop: true }}
          primaryText='Groups'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='/blacklist'
          state={{ _scrollToTop: true }}
          primaryText='Blacklist'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuPushNotifications')}
        isOpen={state.menuPushNotifications}
        name='Push Notifications'
        icon={<NotificationsActiveIcon fontSize='medium' />}
        dense={false}
      >
        <MenuItemLink
          to='/apps'
          state={{ _scrollToTop: true }}
          primaryText='Apps'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='push-service/campaigns'
          state={{ _scrollToTop: true }}
          primaryText='Campaigns'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='/appGroups'
          primaryText='App groups'
          // leftIcon={<TrendingUpIcon fontSize='medium' /> as any}
        />
        <MenuItemLink
          to='/momentSend'
          state={{ _scrollToTop: true }}
          primaryText='Moment sending'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='/autoSend'
          state={{ _scrollToTop: true }}
          primaryText='Automatically sending'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        <MenuItemLink
          to='/push-service/push'
          state={{ _scrollToTop: true }}
          primaryText='Task list'
          // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}
        />
        {/*<MenuItemLink*/}
        {/*  to='/getAnalytic'*/}
        {/*  state={{ _scrollToTop: true }}*/}
        {/*  primaryText='Analytics'*/}
        {/*  // leftIcon={<FiberManualRecordIcon sx={{ height: '10px', width: '10px', ml: '18px' }} />}*/}
        {/*/>*/}
      </SubMenu>
    </Box>
  );
};

export default Menu;
