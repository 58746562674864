import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton, FunctionField, BooleanField,
} from 'react-admin';
import { InBotFalse, InBotTrue } from '../apps/AppList';

const AppGroupList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='demoId' label='#' />
        <TextField source='name' />
        <FunctionField
          label='Apps'
          render={(record: any) => {
            return (<div>{record.apps.length}</div>);
          }}
        />
        {/*<FunctionField*/}
        {/*  label='Status'*/}
        {/*  render={(record: any) => {*/}
        {/*    if (record.isEnabled) {*/}
        {/*      return (*/}
        {/*        <Enabled/>*/}
        {/*      );*/}
        {/*    } else {*/}
        {/*      return (*/}
        {/*        <Disabled />*/}
        {/*      );*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Switch defaultChecked />*/}
        <BooleanField
          source='isEnabled'
          label='Status'
          TrueIcon={InBotTrue as any}
          FalseIcon={InBotFalse as any}
        />,
        <EditButton />
        {/*<DeleteButton mutationMode='pessimistic' />*/}
      </Datagrid>
    </List>
  )
}

export default AppGroupList;
