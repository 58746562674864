import React from 'react';
import {
  List,
  TextField,
  DeleteButton,
  FunctionField,
  DatagridConfigurable,
} from 'react-admin';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CustomPushFilter from './CustomPushFilter';
import { Link } from 'react-router-dom';

const PushList = (props: any) => {
  return (
    <Box>
      <List
        {...props}
        filters={<CustomPushFilter />}
      >
        <DatagridConfigurable
          rowClick='edit'
        >
          <TextField source='demoId' label='#' />
          <FunctionField
            label='Name'
            render={(record: any) => {
              return (
                <Link
                  target='_blank'
                  onClick={(event: any) => event.stopPropagation()}
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                  to={`https://mirapanelapps.com/#/push-service/push/${record.id}`}
                >
                  {record.name}
                </Link>);
            }}
          />
          <FunctionField
            label='Status'
            render={(record: any) => {
              if (record.status === 'Активно') {
                return (
                  <TextField sx={{ color: 'green', fontWeight: '700' }} source='status' />
                );
              } else {
                return (
                  <TextField sx={{ color: 'orange', fontWeight: '700' }} source='status' />
                );
              }
            }}
          />
          <FunctionField
            label='Сountries'
            render={(record: any) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {record.country.map((country: string) =>(
                    <Chip label={country} size="small" color="primary" />
                  ))}
                </Stack>
              );
            }}
          />
          <FunctionField
            label='Languages'
            render={(record: any) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {record.language.map((language: string) =>(
                    <Chip label={language} size="small" color="info" />
                  ))}
                </Stack>
              );
            }}
          />
          <FunctionField
            label='Days'
            render={(record: any) => {
              if (record.days.length === 7) {
                return (<div>Every day</div>)
              } else {
                return (
                  <Stack
                    spacing={1}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    {record.days.map((day: string) =>(
                      <Chip label={day} variant="outlined" />
                    ))}
                  </Stack>
                );
              }
            }}
          />
          <FunctionField
            label='Time'
            render={(record: any) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {record.time.map((value: any) =>(
                    <Chip label={value} size="small" />
                  ))}
                </Stack>
              );
            }}
          />
          <FunctionField
            label='Country Time'
            render={(record: any) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {record.time_country.map((value: any) =>(
                    <Chip label={value} size="small" />
                  ))}
                </Stack>
              );
            }}
          />
          <TextField source='event' />
          <DeleteButton mutationMode='pessimistic' />
        </DatagridConfigurable>
      </List>
    </Box>
  );
};

export default PushList;
