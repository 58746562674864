import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput, BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
} from 'react-admin';

const GroupEdit = (props: any) => {
  const [offersId, setOffersId] = useState([]);
  const { data }: any = useGetList(
    'keitaro/offersID',
  );

  useEffect(() => {
    if (data) {
      setOffersId(data);
    }
  }, [data]);
  return (
    <Edit
      title='Edit Group'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <TextInput source='botKey' />
        <AutocompleteArrayInput sx={{ width: 400 }} source='offers_id' choices={offersId} />
        <TextInput source='name'  />
      </SimpleForm>
    </Edit>
  )
}

export default GroupEdit
