const AllAppsRowStyle = (record: any, theme: string) => {

  let style = {};

  if (!record) {
    return style;
  }

  if (record.fbMark) {
    if (theme === 'dark') {
      style = {
        ...style,
        backgroundColor: 'rgba(0,53,94,0.8)'
      };
    } else {
      style = {
        ...style,
        backgroundColor: '#FFFFE0'
      };
    }
  }

  if (record.isBaned) {
    if (theme === 'dark') {
      style = {
        ...style,
        backgroundColor: 'rgba(65,6,6,0.55)'
      };
    } else {
      style = {
        ...style,
        backgroundColor: '#FFC0CB'
      };
    }
  }

  return style;
};

export default AllAppsRowStyle;
