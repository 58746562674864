import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-admin';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { red } from '@material-ui/core/colors';

export const CountdownTimer = ({ endDate }: any) => {
  const [timeLeft, setTimeLeft] = useState(endDate - Date.now());
  const [theme, ] = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(endDate - Date.now());
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [endDate]);

  const formatTime = (time: any) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    return `Change in ${hours > 0 ? hours  + 'h.' : ''} ${minutes}m.`;
  };

  if (timeLeft <= 0) {
    return (<ReportProblemIcon sx={{ color: theme === 'dark' ? '#FFB800' : red[700] }} />);
  }

  return <span>{formatTime(timeLeft)}</span>;
};
