import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import Menu from './Menu';
import { AppBar, ToggleThemeButton } from 'react-admin';

// const MyAppBar = () => (
//   <AppBar toolbar={<ToggleThemeButton  darkTheme={darkTheme} />} />
// );

export const MyLayout = (props: LayoutProps) => (
  <Layout {...props} menu={Menu} />
);
