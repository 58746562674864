export const closedGeos = [
  {
    "id": "AM",
    "name": "AM"
  },
  {
    "id": "AR",
    "name": "AR"
  },
  {
    "id": "AT",
    "name": "AT"
  },
  {
    "id": "AU",
    "name": "AU"
  },
  {
    "id": "AZ",
    "name": "AZ"
  },
  {
    "id": "AL",
    "name": "AL"
  },
  {
    "id": "BA",
    "name": "BA"
  },
  {
    "id": "BD",
    "name": "BD"
  },
  {
    "id": "BE",
    "name": "BE"
  },
  {
    "id": "BG",
    "name": "BG"
  },
  {
    "id": "BH",
    "name": "BH"
  },
  {
    "id": "BL",
    "name": "BL"
  },
  {
    "id": "BR",
    "name": "BR"
  },
  {
    "id": "BY",
    "name": "BY"
  },
  {
    "id": "CA",
    "name": "CA"
  },
  {
    "id": "CH",
    "name": "CH"
  },
  {
    "id": "CI",
    "name": "CI"
  },
  {
    "id": "CL",
    "name": "CL"
  },
  {
    "id": "CO",
    "name": "CO"
  },
  {
    "id": "CR",
    "name": "CR"
  },
  {
    "id": "CY",
    "name": "CY"
  },
  {
    "id": "CZ",
    "name": "CZ"
  },
  {
    "id": "DE",
    "name": "DE"
  },
  {
    "id": "DK",
    "name": "DK"
  },
  {
    "id": "EE",
    "name": "EE"
  },
  {
    "id": "ES",
    "name": "ES"
  },
  {
    "id": "FI",
    "name": "FI"
  },
  {
    "id": "FR",
    "name": "FR"
  },
  {
    "id": "GB",
    "name": "GB"
  },
  {
    "id": "GE",
    "name": "GE"
  },
  {
    "id": "GF",
    "name": "GF"
  },
  {
    "id": "GH",
    "name": "GH"
  },
  {
    "id": "GP",
    "name": "GP"
  },
  {
    "id": "GR",
    "name": "GR"
  },
  {
    "id": "GT",
    "name": "GT"
  },
  {
    "id": "HR",
    "name": "HR"
  },
  {
    "id": "HU",
    "name": "HU"
  },
  {
    "id": "ID",
    "name": "ID"
  },
  {
    "id": "IE",
    "name": "IE"
  },
  {
    "id": "IN",
    "name": "IN"
  },
  {
    "id": "IS",
    "name": "IS"
  },
  {
    "id": "IT",
    "name": "IT"
  },
  {
    "id": "JP",
    "name": "JP"
  },
  {
    "id": "KG",
    "name": "KG"
  },
  {
    "id": "KW",
    "name": "KW"
  },
  {
    "id": "KZ",
    "name": "KZ"
  },
  {
    "id": "LI",
    "name": "LI"
  },
  {
    "id": "LT",
    "name": "LT"
  },
  {
    "id": "LU",
    "name": "LU"
  },
  {
    "id": "LV",
    "name": "LV"
  },
  {
    "id": "MD",
    "name": "MD"
  },
  {
    "id": "MF",
    "name": "MF"
  },
  {
    "id": "MK",
    "name": "MK"
  },
  {
    "id": "MQ",
    "name": "MQ"
  },
  {
    "id": "MX",
    "name": "MX"
  },
  {
    "id": "MY",
    "name": "MY"
  },
  {
    "id": "NC",
    "name": "NC"
  },
  {
    "id": "NG",
    "name": "NG"
  },
  {
    "id": "NL",
    "name": "NL"
  },
  {
    "id": "NO",
    "name": "NO"
  },
  {
    "id": "NZ",
    "name": "NZ"
  },
  {
    "id": "PE",
    "name": "PE"
  },
  {
    "id": "PF",
    "name": "PF"
  },
  {
    "id": "PH",
    "name": "PH"
  },
  {
    "id": "PK",
    "name": "PK"
  },
  {
    "id": "PL",
    "name": "PL"
  },
  {
    "id": "PM",
    "name": "PM"
  },
  {
    "id": "PT",
    "name": "PT"
  },
  {
    "id": "RE",
    "name": "RE"
  },
  {
    "id": "RO",
    "name": "RO"
  },
  {
    "id": "RU",
    "name": "RU"
  },
  {
    "id": "SE",
    "name": "SE"
  },
  {
    "id": "SG",
    "name": "SG"
  },
  {
    "id": "SI",
    "name": "SI"
  },
  {
    "id": "SK",
    "name": "SK"
  },
  {
    "id": "SL",
    "name": "SL"
  },
  {
    "id": "SN",
    "name": "SN"
  },
  {
    "id": "SV",
    "name": "SV"
  },
  {
    "id": "TH",
    "name": "TH"
  },
  {
    "id": "TJ",
    "name": "TJ"
  },
  {
    "id": "TR",
    "name": "TR"
  },
  {
    "id": "UA",
    "name": "UA"
  },
  {
    "id": "UY",
    "name": "UY"
  },
  {
    "id": "UZ",
    "name": "UZ"
  },
  {
    "id": "VN",
    "name": "VN"
  },
  {
    "id": "WF",
    "name": "WF"
  },
  {
    "id": "YT",
    "name": "YT"
  },
  {
    "id": "ZA",
    "name": "ZA"
  },
  {
    "id": "DZ",
    "name": "DZ"
  },
  {
    "id": "AE",
    "name": "AE"
  },
  {
    "id": "TN",
    "name": "TN"
  },
  {
    "id": "QA",
    "name": "QA"
  },
  {
    "id": "SA",
    "name": "SA"
  },
  {
    "id": "KR",
    "name": "KR"
  },
  {
    "id": "KP",
    "name": "KP"
  },
  {
    "id": "AS",
    "name": "AS"
  },
  {
    "id": "AI",
    "name": "AI"
  },
  {
    "id": "AO",
    "name": "AO"
  },
  {
    "id": "AD",
    "name": "AD"
  },
  {
    "id": "AQ",
    "name": "AQ"
  },
  {
    "id": "AG",
    "name": "AG"
  },
  {
    "id": "AW",
    "name": "AW"
  },
  {
    "id": "AF",
    "name": "AF"
  },
  {
    "id": "BS",
    "name": "BS"
  },
  {
    "id": "BB",
    "name": "BB"
  },
  {
    "id": "BZ",
    "name": "BZ"
  },
  {
    "id": "BJ",
    "name": "BJ"
  },
  {
    "id": "BM",
    "name": "BM"
  },
  {
    "id": "BO",
    "name": "BO"
  },
  {
    "id": "BQ",
    "name": "BQ"
  },
  {
    "id": "BW",
    "name": "BW"
  },
  {
    "id": "IO",
    "name": "IO"
  },
  {
    "id": "BN",
    "name": "BN"
  },
  {
    "id": "BF",
    "name": "BF"
  },
  {
    "id": "BI",
    "name": "BI"
  },
  {
    "id": "BT",
    "name": "BT"
  },
  {
    "id": "VU",
    "name": "VU"
  },
  {
    "id": "VE",
    "name": "VE"
  },
  {
    "id": "VG",
    "name": "VG"
  },
  {
    "id": "VI",
    "name": "VI"
  },
  {
    "id": "GA",
    "name": "GA"
  },
  {
    "id": "HT",
    "name": "HT"
  },
  {
    "id": "GY",
    "name": "GY"
  },
  {
    "id": "GM",
    "name": "GM"
  },
  {
    "id": "GN",
    "name": "GN"
  },
  {
    "id": "GW",
    "name": "GW"
  },
  {
    "id": "GG",
    "name": "GG"
  },
  {
    "id": "GI",
    "name": "GI"
  },
  {
    "id": "HN",
    "name": "HN"
  },
  {
    "id": "HK",
    "name": "HK"
  },
  {
    "id": "GD",
    "name": "GD"
  },
  {
    "id": "GL",
    "name": "GL"
  },
  {
    "id": "GU",
    "name": "GU"
  },
  {
    "id": "JE",
    "name": "JE"
  },
  {
    "id": "DJ",
    "name": "DJ"
  },
  {
    "id": "DM",
    "name": "DM"
  },
  {
    "id": "DO",
    "name": "DO"
  },
  {
    "id": "EG",
    "name": "EG"
  },
  {
    "id": "ZM",
    "name": "ZM"
  },
  {
    "id": "EH",
    "name": "EH"
  },
  {
    "id": "ZW",
    "name": "ZW"
  },
  {
    "id": "IL",
    "name": "IL"
  },
  {
    "id": "JO",
    "name": "JO"
  },
  {
    "id": "IQ",
    "name": "IQ"
  },
  {
    "id": "IR",
    "name": "IR"
  },
  {
    "id": "YE",
    "name": "YE"
  },
  {
    "id": "CV",
    "name": "CV"
  },
  {
    "id": "KH",
    "name": "KH"
  },
  {
    "id": "CM",
    "name": "CM"
  },
  {
    "id": "KE",
    "name": "KE"
  },
  {
    "id": "KI",
    "name": "KI"
  },
  {
    "id": "CN",
    "name": "CN"
  },
  {
    "id": "CC",
    "name": "CC"
  },
  {
    "id": "KM",
    "name": "KM"
  },
  {
    "id": "CG",
    "name": "CG"
  },
  {
    "id": "CD",
    "name": "CD"
  },
  {
    "id": "CU",
    "name": "CU"
  },
  {
    "id": "CW",
    "name": "CW"
  },
  {
    "id": "LA",
    "name": "LA"
  },
  {
    "id": "LS",
    "name": "LS"
  },
  {
    "id": "LB",
    "name": "LB"
  },
  {
    "id": "LY",
    "name": "LY"
  },
  {
    "id": "LR",
    "name": "LR"
  },
  {
    "id": "MU",
    "name": "MU"
  },
  {
    "id": "MR",
    "name": "MR"
  },
  {
    "id": "MG",
    "name": "MG"
  },
  {
    "id": "MO",
    "name": "MO"
  },
  {
    "id": "MW",
    "name": "MW"
  },
  {
    "id": "ML",
    "name": "ML"
  },
  {
    "id": "UM",
    "name": "UM"
  },
  {
    "id": "MV",
    "name": "MV"
  },
  {
    "id": "MT",
    "name": "MT"
  },
  {
    "id": "MA",
    "name": "MA"
  },
  {
    "id": "MH",
    "name": "MH"
  },
  {
    "id": "FM",
    "name": "FM"
  },
  {
    "id": "MZ",
    "name": "MZ"
  },
  {
    "id": "MC",
    "name": "MC"
  },
  {
    "id": "MN",
    "name": "MN"
  },
  {
    "id": "MS",
    "name": "MS"
  },
  {
    "id": "MM",
    "name": "MM"
  },
  {
    "id": "NA",
    "name": "NA"
  },
  {
    "id": "NR",
    "name": "NR"
  },
  {
    "id": "NP",
    "name": "NP"
  },
  {
    "id": "NE",
    "name": "NE"
  },
  {
    "id": "NI",
    "name": "NI"
  },
  {
    "id": "NU",
    "name": "NU"
  },
  {
    "id": "OM",
    "name": "OM"
  },
  {
    "id": "BV",
    "name": "BV"
  },
  {
    "id": "IM",
    "name": "IM"
  },
  {
    "id": "NF",
    "name": "NF"
  },
  {
    "id": "CX",
    "name": "CX"
  },
  {
    "id": "HM",
    "name": "HM"
  },
  {
    "id": "KY",
    "name": "KY"
  },
  {
    "id": "CK",
    "name": "CK"
  },
  {
    "id": "TC",
    "name": "TC"
  },
  {
    "id": "PW",
    "name": "PW"
  },
  {
    "id": "PS",
    "name": "PS"
  },
  {
    "id": "PA",
    "name": "PA"
  },
  {
    "id": "VA",
    "name": "VA"
  },
  {
    "id": "PG",
    "name": "PG"
  },
  {
    "id": "PY",
    "name": "PY"
  },
  {
    "id": "PN",
    "name": "PN"
  },
  {
    "id": "PR",
    "name": "PR"
  },
  {
    "id": "RW",
    "name": "RW"
  },
  {
    "id": "WS",
    "name": "WS"
  },
  {
    "id": "SM",
    "name": "SM"
  },
  {
    "id": "ST",
    "name": "ST"
  },
  {
    "id": "SH",
    "name": "SH"
  },
  {
    "id": "MP",
    "name": "MP"
  },
  {
    "id": "VC",
    "name": "VC"
  },
  {
    "id": "KN",
    "name": "KN"
  },
  {
    "id": "LC",
    "name": "LC"
  },
  {
    "id": "RS",
    "name": "RS"
  },
  {
    "id": "SC",
    "name": "SC"
  },
  {
    "id": "SX",
    "name": "SX"
  },
  {
    "id": "SY",
    "name": "SY"
  },
  {
    "id": "US",
    "name": "US"
  },
  {
    "id": "SO",
    "name": "SO"
  },
  {
    "id": "SB",
    "name": "SB"
  },
  {
    "id": "SD",
    "name": "SD"
  },
  {
    "id": "SR",
    "name": "SR"
  },
  {
    "id": "TW",
    "name": "TW"
  },
  {
    "id": "TZ",
    "name": "TZ"
  },
  {
    "id": "TL",
    "name": "TL"
  },
  {
    "id": "TG",
    "name": "TG"
  },
  {
    "id": "TK",
    "name": "TK"
  },
  {
    "id": "TO",
    "name": "TO"
  },
  {
    "id": "TT",
    "name": "TT"
  },
  {
    "id": "TV",
    "name": "TV"
  },
  {
    "id": "TM",
    "name": "TM"
  },
  {
    "id": "UG",
    "name": "UG"
  },
  {
    "id": "FO",
    "name": "FO"
  },
  {
    "id": "FJ",
    "name": "FJ"
  },
  {
    "id": "FK",
    "name": "FK"
  },
  {
    "id": "TF",
    "name": "TF"
  },
  {
    "id": "CF",
    "name": "CF"
  },
  {
    "id": "TD",
    "name": "TD"
  },
  {
    "id": "ME",
    "name": "ME"
  },
  {
    "id": "SJ",
    "name": "SJ"
  },
  {
    "id": "LK",
    "name": "LK"
  },
  {
    "id": "EC",
    "name": "EC"
  },
  {
    "id": "GQ",
    "name": "GQ"
  },
  {
    "id": "AX",
    "name": "AX"
  },
  {
    "id": "ER",
    "name": "ER"
  },
  {
    "id": "SZ",
    "name": "SZ"
  },
  {
    "id": "ET",
    "name": "ET"
  },
  {
    "id": "GS",
    "name": "GS"
  },
  {
    "id": "OS",
    "name": "OS"
  },
  {
    "id": "SS",
    "name": "SS"
  },
  {
    "id": "JM",
    "name": "JM"
  }
];
