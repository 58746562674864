import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const AccountCreate = (props: any) => {
  return (
    <Create title='Create a User' {...props}>
      <SimpleForm>
        <TextInput source='login' />
        <TextInput source='proxy' fullWidth />
        <TextInput source='cookies' resettable multiline fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default AccountCreate
