import React, { useEffect, useState } from 'react';
import { AutocompleteArrayInput, BooleanInput, Create, SimpleForm, TextInput, useGetList } from 'react-admin';
import { Grid } from '@mui/material';

const AppGroupCreate = (props: any) => {
  const [apps, setApps] = useState([]);

  const appList: any = useGetList(
    'appsList',
  );

  useEffect(() => {
    if (appList.data) {
      setApps(appList.data?.map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appList.data]);

  return (
    <Create title='Create Group' {...props}>
      <SimpleForm>
        <Grid container sx={{ pl: '30px', pr: '30px' }}>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
            {/*<Grid item xs={6} sx={{ display: 'flex' }} >*/}
              <TextInput source='name' sx={{ minWidth: '300px' }} />
            {/*</Grid>*/}

            {/*<Grid item xs={6} sx={{ display: 'flex' }} >*/}
              <BooleanInput label='Enable' source='isEnabled' sx={{ mt: '15px' }} />
            {/*</Grid>*/}
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', columnGap: '30px' }}>
            <AutocompleteArrayInput
              source='apps'
              choices={apps}
              fullWidth
              // resettable
              // multiline
              // fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default AppGroupCreate;