import React, { useEffect, useState } from 'react';
import { Button, CreateButton, SelectColumnsButton, TopToolbar, useGetList, useNotify } from 'react-admin';
import { Alert, AlertTitle } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ShareIcon from '@mui/icons-material/Share';
import { useClipboard } from 'use-clipboard-copy';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MessageIcon from '@mui/icons-material/Message';
import { API_URL } from '../../App';

function formatApplications(applications: any) {
  const filteredApplications = applications.filter((app: any) => app.isBaned === false && app.bot_available === true);

  const applicationsByType = filteredApplications.reduce((acc: any, app: any) => {
    if (!acc[app.vertical]) {
      acc[app.vertical] = [];
    }
    acc[app.vertical].push(app);
    return acc;
  }, {});

  let formattedString = 'Текущие приложения подключены к Вашему неймингу\n\n';

  Object.entries(applicationsByType).forEach(([type, apps]: any) => {
    formattedString += `${type}:\n`;
    apps.forEach((app: any) => {
      formattedString += `${app.name}\n`;
    });
    formattedString += '\n';
  });

  formattedString += 'Новые будут подключены автоматически';

  return formattedString;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ListActions = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const notify = useNotify();

  const handleCheckGeoClick = () => {
    notify(
      <Alert severity='info' variant='filled'>
        <strong>The GEO check</strong> has started!
        <p>It takes a few minutes to check</p>
      </Alert>,
      {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });

    fetch('https://mirapanelapps.com/backend/checkGeo')
      // fetch(`${process.env.REACT_APP_API_URL}/checkGeo`)
      .then((response) => {
        notify(
          <Alert severity='success' variant='filled'>
            <AlertTitle>Success</AlertTitle>
            <strong>The GEO check</strong> completed successfully!
          </Alert>,
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          });
        return response.json()
      })
      .then((data) => {
        const formattedString = data
          .map((item: any) => `${item.name}: ${item.newClosedGeo.map((geo: string) => `${geo}`).join(', ')}`)
          .join("\n");

        return alert(
          `${data.length > 0 ? `${formattedString}\n` : ''}` +
          `${!data.length ? 'Closed GEO have not changed!' : ''}`,
        );
      })
      .catch((error) => console.error(error));
  }

  const handleStatusClick = () => {

    notify(
      <Alert severity='success' variant='filled'>
        <AlertTitle>Success</AlertTitle>
        <strong>Apps status check</strong> started!
      </Alert>,
      {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });

    // fetch('https://mirapanelapps.com/backend/checkStatus')
      fetch(`${API_URL}/checkStatus`)
      .then((response) => response.json())
      .then((data) => {
        alert(
          `${data.banned.length > 0 ? `BANNED: ${data.banned.join(', ')}\n` : ''}` +
          `${data.unbanned.length > 0 ? `UNBANNED: ${data.unbanned.join(', ')}\n` : ''}` +
          `${!data.unbanned.length && !data.banned.length ? 'Applications statuses have not changed!' : ''}`,
        );
      })
      .catch((error) => console.error(error));
  };

  const handleForceShareClick = () => {
    notify(
      <Alert severity='success' variant='filled'>
        <AlertTitle>Success</AlertTitle>
        <strong>Force Share</strong> started!
      </Alert>,
      {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });

    fetch('https://mirapanelapps.com/backend/forceShare')
      // fetch(`${process.env.REACT_APP_API_URL}/forceShare`)
      .then((response) => {
      })
      .catch((error) => console.error(error));
  };

  const appList: any = useGetList(
    'appsList',
  );

  useEffect(() => {
    if (appList.data) {
      setMessage(formatApplications(appList.data));
    }
  }, [appList.data]);


  const clipboard = useClipboard();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    clipboard.copy(message);
    setOpen(false);
  };

  return (
    <>
      <TopToolbar>
        <SelectColumnsButton />
        <CreateButton variant='contained' />
        <Button variant="contained" onClick={handleOpen} label='Generate Message'>
          <MessageIcon />
        </Button>

        <Button
          onClick={handleCheckGeoClick}
          label='Blocked GEO update'
          variant='contained'
        >
          <AutorenewIcon />
        </Button>
        <Button
          onClick={handleStatusClick}
          label='Status update'
          variant='contained'
        >
          <AutorenewIcon />
        </Button>
        <Button
          onClick={handleForceShareClick}
          label='Force share'
          variant='contained'
        >
          <ShareIcon />
        </Button>
        {/*<FilterButton />*/}
      </TopToolbar>

      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Message Body
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <pre>
                { message }
              </pre>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button label='COPY' autoFocus onClick={handleCopy} />
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ListActions;
