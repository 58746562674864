import * as React from 'react';
import { Avatar } from '@mui/material';
import { useRecordContext } from 'react-admin';

const AvatarField = ({ size = '25', sx }: any) => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Avatar
      variant="square"
      src={`${record.logo}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      sx={sx}
    />
  );
};

export default AvatarField;
