import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  EditButton,
  Filter,
  SearchInput, BooleanField, FunctionField, useRefresh,
} from 'react-admin';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { API_URL } from '../../App';
import ReplayIcon from '@mui/icons-material/Replay';
import BlockIcon from '@mui/icons-material/Block';

const CustomFilter = (props: any) => (
  <Filter {...props} >
    <SearchInput placeholder='Group name' source='name' resettable alwaysOn />
  </Filter>
);

const GroupList = (props: any) => {
  const refresh = useRefresh();

  return (
    <List
      {...props}
      filters={<CustomFilter />}
    >
      <Datagrid>
        <TextField source='demoId' label='#' />
        <TextField source='botKey' />
        <TextField source='name' />
        <BooleanField source='blacklisted' label='In blacklist' />
        <FunctionField
          render={(record: any) => {
            const { _id, blacklisted } = record;
            const buttonTittle = blacklisted
              ? 'Remove from blacklist'
              : 'Add to blacklist';

            return (
              <Tooltip title={buttonTittle} placement="top">
                <Button onClick={async () => {
                  const response = await axios.patch(
                    `${API_URL}/blacklist/group`,
                    {
                      blacklisted,
                      groupId: _id
                    }
                  );

                  if (response.data.success) {
                    refresh();
                  }
                }}
                >
                  {blacklisted
                    ? <ReplayIcon />
                    : <BlockIcon />
                  }
                </Button>
              </Tooltip>
            );
          }}
        />
        <EditButton />
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  )
}

export default GroupList
