import React from 'react';
import {
  AutocompleteArrayInput,
  Edit, FunctionField,
  SimpleForm,
  TextInput,
} from 'react-admin';

const BlacklistItemEdit = (props: any) => {

  return (
    <Edit
      title='Edit Group'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <TextInput source='groupName' disabled />
        <FunctionField
          render={(record: any) => {
            const choises = record.users.map((user: any) => ({ id: user, name: user }))
            return (
              <AutocompleteArrayInput
                source='blacklistedUsers'
                label='Blacklisted Users'
                choices={choises}
              />
            )
          }}
        />
      </SimpleForm>
    </Edit>
  )
}

export default BlacklistItemEdit;
