import React from 'react';
import {
  List,
  TextField,
  FunctionField,
  DatagridConfigurable, EditButton, useRecordContext, useNotify,
  useTheme,
} from 'react-admin';
import { Alert, AlertTitle, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomCampaignsFilter from './CustomCampaignsFilter';
import OsCamps from './OsCamps';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DuplicateButton = (props: any) => {
  const record = useRecordContext();
  const { id } = record;
  const notify = useNotify();

  const handleClick = () => {
    fetch(`https://mirapanelapps.com/backend//push-service/campaigns/duplicate`, {
      // fetch(`${process.env.REACT_APP_API_URL}/push-service/campaigns/duplicate`, {
      method: 'POST',
      body: JSON.stringify({ campaignId: id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(r => {
        if (r.success) {
          notify(
            <Alert severity='success' variant='filled'>
              <AlertTitle>Success</AlertTitle>
              Campaign duplicated!
            </Alert>,
            {
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        } else {
          notify(
            <Alert severity='error' variant='filled'>
              <AlertTitle>Failed!</AlertTitle>
              Duplicate campaign creation failed!
            </Alert>,
            {
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        }
      })
  }

  return (<Button variant="text" onClick={handleClick}><ContentCopyIcon /></Button>);
};


const CampaignsList = (props: any) => {
  const [theme, ] = useTheme();

  return (
    <Box>
      <List
        {...props}
        filters={<CustomCampaignsFilter />}
      >
        <DatagridConfigurable
          // rowClick='edit'
          expand={<OsCamps />}
        >
          <TextField source='demoId' label='#' />
          <TextField source='ext_id' label='External ID' />
          <FunctionField
            label='Name'
            render={(record: any) => {
              return (
                <Link
                  target='_blank'
                  onClick={(event: any) => event.stopPropagation()}
                  style={{
                    color: theme === 'dark' ? 'whitesmoke' : 'black',
                    textDecoration: 'none',
                  }}
                  to={`https://mirapanelapps.com/#/push-service/campaigns/${record.id}`}
                >
                  {record.name}
                </Link>);
            }}
          />
          <TextField source='created' label='Created' />
          <TextField source='not_after' label='End Date' />
          <TextField source='auditory' label='Auditory' />
          <TextField source='sent' label='Sent' />
          <FunctionField
            label='Sent'
            render={(record: any) => {
              const percentage = record.sent > 0
                ? (record.sent / record.auditory) * 100
                : 0;
              return (<div>{ `${record.sent} (${percentage.toFixed(2)}%)` }</div>);
            }}
          />
          <DuplicateButton />
          <EditButton />
        </DatagridConfigurable>
      </List>
    </Box>
  );
};

export default CampaignsList;
