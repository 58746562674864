import { AutocompleteInput, Filter, TextInput, useGetList } from 'react-admin';
import React, { useEffect, useState } from 'react';

const BotAppsFilter = (props: any) => {
  const [appGroups, setAppGroups] = useState([]);

  const appGroupsList: any = useGetList(
    'appGroups',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (appGroupsList.data) {
      setAppGroups(appGroupsList.data?.filter((gorup: any) => gorup.isEnabled)
        .map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appGroupsList.data]);

  return (
    <Filter {...props} >
      <TextInput placeholder='App name' source='name' resettable alwaysOn />
      <AutocompleteInput
        label='Category'
        placeholder='Category'
        source='os'
        choices={[
          { id: 'Android', name: 'Android' },
          { id: 'iOS', name: 'iOS' },
          { id: 'Amazon', name: 'Amazon' }
        ]}
        alwaysOn
      />
      <AutocompleteInput
        placeholder='Vertical'
        source='vertical'
        choices={[
          { id: 'Gambling', name: 'Gambling' },
          { id: 'Betting', name: 'Betting' },
          { id: 'Dating', name: 'Dating' },
          { id: 'Finance', name: 'Finance' },
        ]}
        alwaysOn
      />
      <AutocompleteInput
        placeholder='App group'
        source='appGroup'
        choices={appGroups}
        alwaysOn
      />
    </Filter>
  )
};

export default BotAppsFilter;
