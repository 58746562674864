import { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Title,
  useGetList,
  useNotify,
} from 'react-admin';
import { Alert, AlertTitle, Card, CardContent, TextField } from '@mui/material';
import { Box } from '@material-ui/core';
import * as React from 'react';
import AutocompleteWithSelectAllMixin from "./AutoComplete";
import { API_URL } from '../../App';

const BotMessage = (props: any) => {
  const [groups, setGroups] = useState([]);
  const [columns, setColumns] = useState([]);

  const notify = useNotify();

  const groupsList: any = useGetList(
    'groupsList',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (groupsList.data) {
      setGroups(groupsList.data?.map((record: any) => ({ id: record.name, name: record.name })));
    }
  }, [groupsList.data]);

  const handleSubmit = (values: any) => {
    const body = {
      ...values,
      availableFor: columns.map((column: any) => (column.name)),
      message: values.message.reduce((obj: any, { lang, text }: any) => ({ ...obj, [lang]: text }), {}),
    }

    if (!body.message['en']) {
      notify(
        <Alert severity='warning' variant='filled'>
          <AlertTitle>Warning!</AlertTitle>
          Add an English version of the message!
        </Alert>,
        {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        });

      return;
    }

    fetch(`${API_URL}/sendMessage`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        notify(
          <Alert severity='success' variant='filled'>
            <AlertTitle>Success</AlertTitle>
            Message sent!
          </Alert>,
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          });
      })
      .catch(error => {
      });
  };

  return (
    <Card>
      <Title title='Bot Message' />
      <CardContent>
        <h3 style={{ color: 'grey' }} >Send message for all users:</h3>
        <SimpleForm onSubmit={handleSubmit} toolbar={<SaveButton label='Send' />}>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source='imgUrl' name='imgUrl' resettable />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} sx={{ width: '300px' }}>
              <AutocompleteWithSelectAllMixin
                multiple
                fullWidth
                options={groups}
                setValue={setColumns}
                disableCloseOnSelect
                filterSelectedOptions
                freeSolo={false}
                value={columns}
                renderInput={(params: any) => (<TextField {...params} label={'Only available for'} />)}
              />
            </Box>
          </Box>
          <ArrayInput {...props} source='message' label='Message'>
            <SimpleFormIterator
              disableReordering
              sx={{
                '& .RaSimpleFormIterator-form': {
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '80%',
                },
              }}
            >
              <AutocompleteInput
                label='Language'
                source='lang'
                choices={[
                  { id: 'ua', name: 'Ukrainian' },
                  { id: 'en', name: 'English' },
                  { id: 'ru', name: 'Russian' }
                ]}
                sx={{
                  marginRight: '20px'
                }}
              />
              <TextInput
                resettable
                source='text'
                label='Message'
                multiline
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </CardContent>
    </Card>

  );
};

export default BotMessage;
